import { SingleSelect } from "../../../../Form/Inputs/Generic/SingleSelect/SingleSelect";
import {
   locationDropDownOptions,
   locationOrAccountDropdownOptions,
} from "../../../../../consts";
import React, { useContext } from "react";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";

export function MobileOrDonationCenterDropdown() {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   return (
      <SingleSelect
         values={locationDropDownOptions}
         selectedValue={
            locationDropDownOptions.find(
               o => o.value === filters?.locationType,
            )!
         }
         onValueSelected={value => updateFilter({ locationType: value.value })}
         border={false}
      />
   );
}
