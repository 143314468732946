import Button from "react-bootstrap/Button";
import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const MapButton = styled(Button)`
   color: ${PRIMARY_COLORS.PALE_SKY};
   font-weight: 700;
   background: ${PRIMARY_COLORS.SOAP_STONE};
   border: 1px solid gray;
   margin-bottom: 8px;
   border-radius: 12px;
   svg {
      padding-right: 5px;
      scale: 1.2;
      stroke: ${PRIMARY_COLORS.PALE_SKY};
   }
   &:hover {
      background-color: ${PRIMARY_COLORS.SOAP_STONE};
      border: 1px solid gray;
      color: ${PRIMARY_COLORS.PALE_SKY};
   }
`;

export const Div = styled.div`
   position: sticky;
   bottom: 0;
   z-index: 2;
`;

export const BorderedShadow = styled.div`
   box-shadow: 1px 1px 7px 1px rgba(0, 0, 0, 0.3);
   border-radius: 5px;
   margin-bottom: 20px;
`;

export const Container = styled.div`
   position: relative;
   height: calc(100vh - 62px);
`;
