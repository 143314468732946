import React, { useState } from "react";
import MobileMapViewWithFilters from "./MobileMapViewWithFilters";
import MobileMapView from "./MobileMapView";
import MobileListViewWithFilters from "./MobileListViewWithFilters";
import { MobileLayoutSteps } from "app/consts";
import styled from "styled-components";

export const Div = styled.div`
   height: calc(100vh - 62px);
   position: relative;
`;

const MobileLayout = () => {
   // Show the list with filters when changing filters from landing view.(AEP-60)
   const [layout, setLayout] = useState(MobileLayoutSteps.MOBILE_LIST_VIEW_WITH_FILTERS);

   const renderMobileLayout = React.useCallback(() => {
      switch (layout) {
         case MobileLayoutSteps.MOBILE_LIST_VIEW_WITH_FILTERS:
            return <MobileListViewWithFilters setLayout={setLayout} />;
         case MobileLayoutSteps.MOBILE_MAP_VIEW_WITH_FILTERS:
            return <MobileMapViewWithFilters setLayout={setLayout} />;
         case MobileLayoutSteps.MOBILE_MAP_VIEW:
            return (
               <Div>
                  <MobileMapView setLayout={setLayout} />
               </Div>
            );
      }
   }, [layout]);

   return renderMobileLayout();
};

export default MobileLayout;
