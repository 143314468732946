import React, { useContext } from "react";
import HeaderCard from "../MyDonationHeaderCard";
import { BiStar } from "react-icons/bi";
import { PRIMARY_COLORS } from "app/consts/colors";
import { ReactComponent as DonationTitleIcon } from "assets/svgs/donationTitleIcon.svg";
import {
   IUserContext,
   UserContext,
} from "../../../../contexts/user/userContext";
import { Section } from "../../../../components/Section/Section";

export interface HeaderCardProps {
   icon: JSX.Element;
   title: string;
   lifeTime: string | number;
   yearToDate: string | number;
   previous12Months: string | number;
   headerbB: string;
}

const MyDonationHeader = () => {
   const { profileData } = useContext<IUserContext>(UserContext);

   const wholeBlood: HeaderCardProps = {
      icon: <DonationTitleIcon fill="white" width={20} height="auto" />,
      title: "Whole Blood",
      lifeTime: profileData?.donationTotals.wholeBlood.lifetime || "",
      yearToDate: profileData?.donationTotals.wholeBlood.yearToDate || "",
      previous12Months: profileData?.donationTotals.wholeBlood.oneYear || "",
      headerbB: `radial-gradient(
            circle at center,
            ${PRIMARY_COLORS.CRYSTAL_BLUE} 4%,
            ${PRIMARY_COLORS.BLUE_DRESS} 30%
         )`,
   };

   const pheresis: HeaderCardProps = {
      icon: <DonationTitleIcon fill="white" width={20} height="auto" />,
      title: "Pheresis",
      lifeTime: profileData?.donationTotals.pheresis.lifetime || "",
      yearToDate: profileData?.donationTotals.pheresis.yearToDate || "",
      previous12Months: profileData?.donationTotals.pheresis.oneYear || "",
      headerbB: `radial-gradient(
          circle at center,
          ${PRIMARY_COLORS.LAVA_RED} 4%,
          ${PRIMARY_COLORS.JASPER} 30%
       )`,
   };

   const credits: HeaderCardProps = {
      icon: <BiStar fill="white" width={20} height="auto" />,
      title: "Credits",
      lifeTime: profileData?.donationTotals.credits.lifetime || "",
      yearToDate: profileData?.donationTotals.credits.yearToDate || "",
      previous12Months: profileData?.donationTotals.credits.oneYear || "",
      headerbB: `radial-gradient(
        circle at center,
        ${PRIMARY_COLORS.DARK_GREEN_BLUE} 4%,
        ${PRIMARY_COLORS.MYRTLE} 30%
     )`,
   };

   return (
      <Section className={"my-donations-header-section"}>
         <div className="row  g-3">
            <div className="col-lg-4 col-sm-12">
               <HeaderCard card={wholeBlood} />
            </div>
            <div className="col-lg-4 col-sm-12">
               <HeaderCard card={pheresis} />
            </div>
            <div className="col-lg-4 col-sm-12">
               <HeaderCard card={credits} />
            </div>
         </div>
      </Section>
   );
};

export default MyDonationHeader;
