import { createGlobalStyle } from "styled-components";
import "primereact/resources/themes/lara-light-purple/theme.css"; //theme
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import { PRIMARY_COLORS } from "../app/consts/colors";
const chevronIcon = require("../assets/svgs/caret-down-fill.svg").default;

export const GlobalStyle = createGlobalStyle`
  html,
  body {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  body {
   font-family: 'Liberation Sans', sans-serif;
  }

  #root {
    min-height: 100%;
    min-width: 100%;
  }

  h1,h2,h3,h4,h5,p{
    margin:0
  }

  p,
  label {
    font-family: serif, Times, 'Times New Roman', ;
    line-height: 1.5em;
  }

  input, select {
    font-family: inherit;
    font-size: inherit;
  }

  .form-control.is-invalid {
    background-image: url('../assets/');
  }

  .dropdown-toggle::after {
    font-size: 24px;
    background-image: url(${chevronIcon});
  }

  .container {
    width: 85%;
  }
  @media (max-width: 768px)  {
    .container{
      width: 100%;
    }
  }

  .hr {
    margin-top: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.activee {
      box-shadow: 0 0 5px blue;
   }

/*  REACT-SELECT-OVERRIDE */
#react-select-3-placeholder  {
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  display: initial;
}

//scroll-bar-style-override atart *********
/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  border-radius: 5px;
  border: 2px solid lightgray;
}

/* Handle */
::-webkit-scrollbar-thumb {
  /* background: gray; */
  border-radius: 5px;
  border: 2px solid gray;
  /* box-shadow: 1px 1px 1px 2px gray; */
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: none;
  border: 3px solid gray;
}


//scroll-bar-style-override end *********
input, span, select,p {
  font-size: 14px !important;
}

.orderLast {
order: 2;
}

.orderFirst {
order:1
}

.map-container {
  width: 100%;
  height: 100vh;
}

/* hide map info windows close icon  */
.gm-ui-hover-effect{
  display:  none  !important;
}

.gm-style-iw-c {
  padding: 0 !important;
  background-color: transparent !important;
  box-shadow  : none !important;
  height: 140px !important;
  min-width: 200px !important;
  min-width: 220px !important;
  :focus{
    border:none !important
  }
}

.gm-style-iw {
  :focus{
    border:none !important
  }
}

.gm-style-iw-d {
  overflow: auto !important;
  height: 158px !important
}
.gm-style .gm-style-iw-tc{
  display: none !important;
}
.gm-style .gm-style-iw-tc::after {
  all: unset !important;
}

.modal-backdrop{
  z-index: 800;
}
.p-dropdown-panel{
  .p-dropdown-items{
    .p-dropdown-item.p-highlight{
      color:${PRIMARY_COLORS.TORY_BLUE};
      background: ${PRIMARY_COLORS.LILY_WHITE};
    }
  }
}
  .p-multiselect-panel{
    .p-multiselect-items{
      .p-multiselect-item.p-highlight {
        color:${PRIMARY_COLORS.TORY_BLUE};
        background: ${PRIMARY_COLORS.LILY_WHITE};
      }
    }
  }

  
  .p-checkbox .p-checkbox-box {
    border-radius: 0!important;
  }
  .p-checkbox  {
    .p-checkbox-box.p-highlight {
      border-color: ${PRIMARY_COLORS.TORY_BLUE};
      background: white !important;
      border-radius: 0;
      .p-checkbox-icon {
        color: ${PRIMARY_COLORS.TORY_BLUE};
        font-weight: bolder;
      }
    }
  }

  .p-dropdown {
    border-color: ${PRIMARY_COLORS.LINK_WATER} !important;
    box-shadow: none!important;
  }
  .p-dropdown:hover {
    background-color: ${PRIMARY_COLORS.AQUA_HAZE};
  }
  .p-multiselect{
    border-color: ${PRIMARY_COLORS.LINK_WATER} !important;
    box-shadow: none!important;
  }
  .p-multiselect:hover {
    background-color: ${PRIMARY_COLORS.AQUA_HAZE};
  }
  .p-dropdown-trigger[aria-expanded="true"]{
    .p-dropdown-trigger-icon{
      transform: rotate(180deg);
    }
  }
  .p-multiselect-trigger[aria-expanded="true"]{
    .p-multiselect-trigger-icon{
      transform: rotate(180deg);
    }
  }
  /* REMOVE BOOTSTRAP DEFAULT VALIDATION CHECK MARK ICON ON TEXT FIELD */
   .form-control.is-valid {
      background-image: none !important;
   }

   
   .p-multiselect-panel{
      z-index: 111111111111 !important;
   }
`;
