import React, { useContext, useEffect, useState } from "react";
import { PageComponent } from "../../components/PageComponent";
import { getDonorDataServiceInstance } from "../../../services/bexWISE/donorDataService/getDonorDataServiceInstance";
import { AuthContext } from "../../contexts/auth/authContext";
import { IDonorContactPreference } from "../../../services/bexWISE/donorDataService/IDonorDataService";
import { useMediaQuery } from "react-responsive";
import { Section } from "../../components/Section/Section";
import { Col, Row } from "react-bootstrap";
import Image from "react-bootstrap/Image";
import PreferencesImg from "../../../assets/images/preferences.png";
import { switchesSectionsContent } from "./consts";
import SwitchSectionComponent from "./components/SwitchSectionComponent";
import { PreferencesContainer } from "./styled";

const PreferencesPage = () => {
   const [preferencesData, setPreferencesData] = useState<
      IDonorContactPreference[]
   >([]);
   const { userDonorId } = useContext(AuthContext);
   const [preferencesDisplayData, setPreferencesDisplayData] =
      useState<IDonorContactPreference[]>(preferencesData);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const padding: string = React.useMemo(
      () => (isMobile ? "" : "p-5"),
      [isMobile],
   );
   useEffect(() => {
      if (userDonorId) {
         getDonorDataServiceInstance()
            .getDonorContactPreferences(userDonorId)
            .then((response: IDonorContactPreference[]) => {
               setPreferencesData(response);
            });
      }
   }, []);

   return (
      <PageComponent
         name={"Preferences Page"}
         title={"Preferences Page"}
         description={"Preferences Page"}
      >
         <PreferencesContainer
            className={isMobile ? "mobile-container-css" : ""}
         >
            <Section
               header={{
                  title: "Preferences",
               }}
            >
               <Row
                  className={`preferences-content-section ${padding} ${
                     isMobile ? "mobile-css" : "desktop-css"
                  }`}
               >
                  {!isMobile && (
                     <Col md={4} className="preferences-left-section">
                        <Image
                           src={PreferencesImg}
                           fluid
                           alt="preferences-img"
                           className="preferences-img-css"
                        />
                        <div className="preferences-img-content">
                           If there are changes needed to any of <br />
                           these contact preferences, please reach
                           <br /> out to your blood center.
                        </div>
                     </Col>
                  )}
                  <Col
                     md={8}
                     sm={12}
                     xs={12}
                     className="preferences-right-section"
                  >
                     <div className="switch-header-content">
                        <b>I prefer to be contacted by:</b>
                     </div>
                     {switchesSectionsContent.map(eachSwitchSection => (
                        <>
                           {eachSwitchSection.id === "unsubscribe" && (
                              <hr className="unsubscribe-hr-css"></hr>
                           )}
                           <SwitchSectionComponent
                              sectionsContent={eachSwitchSection}
                              key={eachSwitchSection.id}
                              preferencesDisplayData={preferencesDisplayData}
                              setPreferencesDisplayData={
                                 setPreferencesDisplayData
                              }
                              isMobile={isMobile}
                           />
                        </>
                     ))}
                     {isMobile && (
                        <div className="preferences-img-mobile-content">
                           If there are changes needed to any of <br />
                           these contact preferences, please reach
                           <br /> out to your blood center.
                        </div>
                     )}
                  </Col>
               </Row>
            </Section>
         </PreferencesContainer>
      </PageComponent>
   );
};

export default PreferencesPage;
