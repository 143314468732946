import styled from "styled-components";
import { Card } from "react-bootstrap";
import { ReactComponent as GeoLocationIcon } from "../../../../../assets/svgs/geo-alt-fill.svg";
import { ReactComponent as TelephoneFillIcon } from "../../../../../assets/svgs/telephone-fill.svg";
import { ReactComponent as ClockFillIcon } from "../../../../../assets/svgs/clock-fill.svg";
import { ReactComponent as SearchIcon } from "../../../../../assets/svgs/search.svg";
import { ReactComponent as BusIcon } from "../../../../../assets/svgs/bus.svg";
import { css } from "styled-components/macro";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const StyledAppointmentCard = styled(Card)`
   display: flex;
   padding: 30px;
   transition: all 0.3s;
   border-color: ${PRIMARY_COLORS.NEBULA};
   border-width: 1px;
`;

export const AppointmentCardHeaderDiv = styled.div`
   display: flex;
   gap: 20px;
   align-items: center;
`;

export const PhoneNumberSpan = styled.span`
   letter-spacing: 1px;
`;

const baseIconStyles = css`
   width: 7px;
   height: 7px;
   fill: gray;
`;

export const StyledBusIcon = styled(BusIcon)`
   width: 20px;
   height: 20px;
   fill: ${PRIMARY_COLORS.CLOUDY_GREY};
`;

export const StyledTelephoneIcon = styled(TelephoneFillIcon)`
   ${baseIconStyles}
`;

export const StyledGeoLocation = styled(GeoLocationIcon)`
   ${baseIconStyles}
`;

export const StyledClockIcon = styled(ClockFillIcon)`
   ${baseIconStyles}
`;

export const StyledSearchIcon = styled(SearchIcon)`
   margin-left: 10px;
`;

export const Title = styled.h5`
   font-size: 20px;
   font-weight: bold;
   font-family: "Liberation Sans", sans-serif !important;
   color: ${PRIMARY_COLORS.DAVY_GREY};
`;

export const Body = styled("div")`
   font-family: "Liberation Sans", sans-serif !important;
   margin-top: 14px;
   font-size: 14px;
   &.mobile-css {
      display: flex;
      flex-direction: column;
      gap: 3px;
      .phone-number-section {
         margin-top: 20px;
      }
   }
   &.desktop-css {
      display: flex;
      align-items: flex-end;
   }
   svg {
      scale: 1.8;
      margin-right: 10px;
   }
   .align-center-items {
      display: flex;
      align-items: center;
   }
   .location-clock-mobile-css {
      display: flex;
      justify-content: space-between;
   }
`;

export const Header = styled("div")`
   display: flex;
   justify-content: space-between;
   align-items: center;
   .change-location-button {
      padding-right: 20px;
      padding-left: 40px;
      font-size: 12px;
      .search-icon {
         width: 12px;
         height: 12px;
      }
   }
`;

export const Footer = styled("div")`
   display: flex;
   flex-direction: column;
   gap: 25px;
   &.desktop-css {
      align-items: flex-end;
   }
   font-size: 14px;
   margin-top: 15px;
   .change-location-button {
      width: 100%;
      font-size: 14px;
   }
   .visit-page-css {
      text-decoration: none;
      color: black;
   }
`;
