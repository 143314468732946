import styled from "styled-components";
import Button from "react-bootstrap/Button";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const MapButtons = styled(Button)`
   color: ${PRIMARY_COLORS.PALE_SKY};
   font-weight: 700;
   border-radius: 12px;
   svg {
      padding-right: 5px;
      scale: 1.2;
      stroke: ${PRIMARY_COLORS.PALE_SKY};
   }
   &:hover {
      background-color: ${PRIMARY_COLORS.SOAP_STONE};
      border-color: 1px ${PRIMARY_COLORS.BRANDEIS_BLUE};
      color: ${PRIMARY_COLORS.PALE_SKY};
   }
`;
export const ButtonContainer = styled("div")`
   position: absolute;
   right: 0;
   bottom: 10px;
   width: 100%;
   display: flex;
   justify-content: space-between;
   padding: 10px 20px;

   button {
      padding: 3px 24px;
      border-radius: 8px;
      border: 1px solid gray;
      font-size: 12px;
      background: ${PRIMARY_COLORS.SOAP_STONE};
   }
`;
