import React, { useContext } from "react";
import {
   BloodCenterImg,
   FacebookImg,
   InstagramImg,
   StyledGeoLocation,
   StyledTelephoneIcon,
   TikTokImg,
   TwitterImg,
   YoutubeImg,
} from "./styled";
import { isValidSocialMediaURL } from "./isValidSocialMediaURL";
import { EnvironmentContext } from "../../contexts/environment/environmentContext";
import { ICenterData } from "../../../services/bexWISE/centerDataService/ICenterDataService";
import { MENTION } from "../../consts/contactInfo";
import ItsyLogo from "../../../assets/images/itsy-logo.png";
import { useMediaQuery } from "react-responsive";

function parseCenterAddress(centerData: ICenterData | null): string {
   if (centerData === null) {
      return "";
   } else {
      return `${centerData.mailingAddress1}, ${centerData.mailingAddressCity} ${centerData.mailingAddressState} ${centerData.mailingAddressZip}`;
   }
}

const Footer = () => {
   const { environmentConfig, centerData } = useContext(EnvironmentContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 1200 });
   const openInNewTab = url => {
      const newWindow = window.open(url, "_blank");
      if (newWindow) newWindow.opener = null;
   };

   return isMobile ? (
      <div id={"layout-footer"}>
         <div id={"footer-powered-by"}>
            <img src={ItsyLogo} className="logo-css" />
            <span>
               {MENTION} <span className="copyright-css">&#169;</span>
               {` 2023`}
            </span>
         </div>
         {!isMobile && (
            <div id={"footer-contacts-desktop"}>
               <div className="contact-section">
                  <BloodCenterImg />
                  <span>{centerData?.name}</span>
               </div>
               <div className="contact-section">
                  <StyledGeoLocation />
                  <span>{parseCenterAddress(centerData)}</span>
               </div>
               <div className="contact-section">
                  <StyledTelephoneIcon />
                  <span>{centerData?.phoneNumber}</span>
               </div>
            </div>
         )}
         {isMobile && (
            <>
               <div className="contact-section">
                  <BloodCenterImg />
                  <span>{centerData?.name}</span>
               </div>
               <div className="contact-section">
                  <StyledGeoLocation />
                  <span>{parseCenterAddress(centerData)}</span>
               </div>
               <div className="contact-section">
                  <StyledTelephoneIcon />
                  <span>{centerData?.phoneNumber}</span>
               </div>
            </>
         )}
         <div id={"footer-social"}>
            {isValidSocialMediaURL(environmentConfig?.facebookUrl) && (
               <FacebookImg
                  onClick={() => openInNewTab(environmentConfig?.facebookUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.instagramUrl) && (
               <InstagramImg
                  onClick={() => openInNewTab(environmentConfig?.instagramUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.twitterUrl) && (
               <TwitterImg
                  onClick={() => openInNewTab(environmentConfig?.twitterUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.tiktokUrl) && (
               <TikTokImg
                  onClick={() => openInNewTab(environmentConfig?.tiktokUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.youtubeUrl) && (
               <YoutubeImg
                  onClick={() => openInNewTab(environmentConfig?.youtubeUrl)}
               />
            )}
         </div>
      </div>
   ) : (
      <div id={"layout-footer"}>
         <div id={"footer-powered-by"}>
            <img src={ItsyLogo} className="logo-css" />
            <span>
               {MENTION} <span className="copyright-css">&#169;</span>
               {` 2023`}
            </span>
         </div>
         <div id={"footer-contacts-desktop"}>
            <div className="contact-section">
               <BloodCenterImg />
               <span>{centerData?.name}</span>
            </div>
            <div className="contact-section">
               <StyledGeoLocation />
               <span>{parseCenterAddress(centerData)}</span>
            </div>
            <div className="contact-section">
               <StyledTelephoneIcon />
               <span>{centerData?.phoneNumber}</span>
            </div>
         </div>
         <div id={"footer-social"}>
            {isValidSocialMediaURL(environmentConfig?.facebookUrl) && (
               <FacebookImg
                  onClick={() => openInNewTab(environmentConfig?.facebookUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.instagramUrl) && (
               <InstagramImg
                  onClick={() => openInNewTab(environmentConfig?.instagramUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.twitterUrl) && (
               <TwitterImg
                  onClick={() => openInNewTab(environmentConfig?.twitterUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.tiktokUrl) && (
               <TikTokImg
                  onClick={() => openInNewTab(environmentConfig?.tiktokUrl)}
               />
            )}
            {isValidSocialMediaURL(environmentConfig?.youtubeUrl) && (
               <YoutubeImg
                  onClick={() => openInNewTab(environmentConfig?.youtubeUrl)}
               />
            )}
         </div>
      </div>
   );
   // return (
   //    <div id={"layout-footer"}>
   //       <FooterSection>
   //          <Col className="first-section" xs={12} sm={6}>
   //             <div className="each-section">
   //                <img src={ItsyLogo} className="logo-css" />
   //                <span>
   //                   {MENTION} <span className="copyright-css">&#169;</span>
   //                   {` 2023`}
   //                </span>
   //             </div>
   //             <div className="each-section">
   //                <BloodCenterImg />
   //                <span>{centerData?.name}</span>
   //             </div>
   //          </Col>
   //          <Col className="last-section-css">
   //             <div className="address-section-css">
   //                <div className="each-section">
   //                   <StyledGeoLocation />
   //                   <span>{parseCenterAddress(centerData)}</span>
   //                </div>
   //                <div className="each-section">
   //                   <StyledTelephoneIcon />
   //                   <span>{centerData?.phoneNumber}</span>
   //                </div>
   //             </div>
   //             <div className="social-network-css">
   //                {isValidSocialMediaURL(environmentConfig?.facebookUrl) && (
   //                   <FacebookImg
   //                      onClick={() =>
   //                         openInNewTab(environmentConfig?.facebookUrl)
   //                      }
   //                   />
   //                )}
   //                {isValidSocialMediaURL(environmentConfig?.instagramUrl) && (
   //                   <InstagramImg
   //                      onClick={() =>
   //                         openInNewTab(environmentConfig?.instagramUrl)
   //                      }
   //                   />
   //                )}
   //                {isValidSocialMediaURL(environmentConfig?.twitterUrl) && (
   //                   <TwitterImg
   //                      onClick={() =>
   //                         openInNewTab(environmentConfig?.twitterUrl)
   //                      }
   //                   />
   //                )}
   //                {isValidSocialMediaURL(environmentConfig?.tiktokUrl) && (
   //                   <TikTokImg
   //                      onClick={() =>
   //                         openInNewTab(environmentConfig?.tiktokUrl)
   //                      }
   //                   />
   //                )}
   //                {isValidSocialMediaURL(environmentConfig?.youtubeUrl) && (
   //                   <YoutubeImg
   //                      onClick={() =>
   //                         openInNewTab(environmentConfig?.youtubeUrl)
   //                      }
   //                   />
   //                )}
   //             </div>
   //          </Col>
   //       </FooterSection>
   //    </div>
   // );
};

export default Footer;
