import { FilterInputForm } from "../../../../Form/Filter/FilterInputForm";
import { FilterInputFormRow } from "../../../../Form/Filter/FilterInputFormRow";
import { LocationOrAccountDropdown } from "../components/LocationOrAccountDropdown";
import { MobileOrDonationCenterDropdown } from "../components/MobileOrDonationCenterDropdown";
import { FilterControlWrapper } from "../../../../Form/Filter/FilterControlWrapper";
import { InputWrapper } from "../../../../Form/Inputs/InputWrapper/InputWrapper";
import Searchbar from "../components/Searchbar";
import FormInputCounter from "../../../../Form/Inputs/Generic/FormInputCounter";
import FormDatePicker from "../../../../Form/Inputs/Generic/FormDatePicker";
import { MoreFiltersModalControl } from "../../../../Form/Inputs/Instances/MoreFiltersModalControl/MoreFiltersModalControl";
import OpportunitySearchBarMoreFiltersModal from "../OppprtunityMoreFilters";
import React, { useContext, useMemo } from "react";
import {
   LocationOrAccountOptions,
   OpportunityLocatorViews,
} from "../../../../../consts";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";
import { OpportunityLocatorViewContext } from "../../../context/OpportunityLocatorViewContext";

export function OpportunitySearchBarFullScreenView() {
   const {
      filters: { searchType },
   } = useContext<IOpportunitySearchContext>(OpportunitySearchContext);
   const { selectedView } = React.useContext(OpportunityLocatorViewContext);

   const rowOne = useMemo(() => {
      if (selectedView === OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT) {
         return (
            <>
               <LocationOrAccountDropdown />
               <MobileOrDonationCenterDropdown />
               <MoreFiltersModalControl
                  moreFiltersModal={OpportunitySearchBarMoreFiltersModal}
               />
            </>
         );
      } else {
         return (
            <>
               <LocationOrAccountDropdown />
               <MobileOrDonationCenterDropdown />
            </>
         );
      }
   }, [selectedView]);

   const rowTwo = useMemo(() => {
      if (selectedView === OpportunityLocatorViews.FULL_MAP_CONTRACTED_LAYOUT) {
         return (
            <>
               <FilterControlWrapper>
                  <InputWrapper>
                     <Searchbar name="address" />
                  </InputWrapper>
               </FilterControlWrapper>
               {searchType === LocationOrAccountOptions.LOCATION && (
                  <FilterControlWrapper>
                     <InputWrapper>
                        <FormInputCounter name="miles" placeholder="Miles" />
                     </InputWrapper>
                  </FilterControlWrapper>
               )}
               <FilterControlWrapper>
                  <InputWrapper>
                     <FormDatePicker name="date" />
                  </InputWrapper>
               </FilterControlWrapper>
            </>
         );
      } else {
         return (
            <>
               <FilterControlWrapper>
                  <InputWrapper>
                     <Searchbar name="address" />
                  </InputWrapper>
               </FilterControlWrapper>
            </>
         );
      }
   }, [selectedView, searchType]);

   return (
      <FilterInputForm>
         <FilterInputFormRow stretch={false}>{rowOne}</FilterInputFormRow>

         <FilterInputFormRow>{rowTwo}</FilterInputFormRow>

         {selectedView === OpportunityLocatorViews.FULL_MAP_EXPANDED_LAYOUT && (
            <>
               <FilterInputFormRow>
                  {searchType === LocationOrAccountOptions.LOCATION && (
                     <FilterControlWrapper>
                        <InputWrapper>
                           <FormInputCounter name="miles" placeholder="Miles" />
                        </InputWrapper>
                     </FilterControlWrapper>
                  )}

                  <FilterControlWrapper>
                     <InputWrapper>
                        <FormDatePicker name="date" />
                     </InputWrapper>
                  </FilterControlWrapper>
               </FilterInputFormRow>

               <FilterInputFormRow>
                  <MoreFiltersModalControl
                     moreFiltersModal={OpportunitySearchBarMoreFiltersModal}
                  />
               </FilterInputFormRow>
            </>
         )}
         {/*<FilterInputFormRow>*/}
         {/*   {searchType === LocationOrAccountOptions.LOCATION && (*/}
         {/*      <FilterControlWrapper>*/}
         {/*         <InputWrapper>*/}
         {/*            <FormInputCounter name="miles" placeholder="Miles" />*/}
         {/*         </InputWrapper>*/}
         {/*      </FilterControlWrapper>*/}
         {/*   )}*/}
         {/*   <FilterControlWrapper>*/}
         {/*      <InputWrapper>*/}
         {/*         <FormDatePicker name="date" />*/}
         {/*      </InputWrapper>*/}
         {/*   </FilterControlWrapper>*/}
         {/*</FilterInputFormRow>*/}
         {/*<FilterInputFormRow>*/}
         {/*   <MoreFiltersModalControl*/}
         {/*      moreFiltersModal={OpportunitySearchBarMoreFiltersModal}*/}
         {/*   />*/}
         {/*</FilterInputFormRow>*/}
      </FilterInputForm>
   );
}
