import styled from "styled-components";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import { PRIMARY_COLORS } from "../../../../consts/colors";
export const CardHeader = styled("div")`
   display: flex;
   gap: 20px;
`;

export const StyledCol = styled(Col)`
   @media (min-width: 541px) {
      width: 15.5%;
   }

   @media (max-width: 541px) {
      width: 38.5%;
   }
`;

export const StyledButton = styled(Button)`
   height: 100%;
   background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
   border-color: transparent !important;
   margin-left: 10px;
   margin-right: 10px;
   width: 50px;
   border-radius: 12px;
`;

export const StyledDivForMultiSelect = styled.div`
   @media (min-width: 1000px) {
      display: flex;
   }
   display: none;
`;

export const StyledDivForMobMultiSelect = styled.div`
   @media (min-width: 1000px) {
      display: none;
   }
   display: flex;
`;

export const Div = styled.div`
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 12px;
   margin-bottom: 8px;
   @media (max-width: 365px) {
      display: flex;
      flex-direction: column;
      align-items: flex-start !important;
   }
`;
