import React, { useEffect } from "react";
import { InfoWindowF } from "@react-google-maps/api";
import {
   BloodDriveMarkerPopupWrapper,
   BloodDriveMarkerSubTitle,
   BloodDriveMarkerTitle,
} from "./styled";
import { IBloodDrive } from "types/IBloodDrive";
import { format } from "date-fns";
import { ReactComponent as DirectionIcon } from "assets/svgs/direction.svg";

interface MarkerPopupProps {
   drive: IBloodDrive;
   setSelectedDrive?: (arg: number) => void;
   onGetDirectionsClicked: () => void;
}

const BloodDriveMarkerPopup: React.FC<MarkerPopupProps> = ({
   drive,
   setSelectedDrive,
   onGetDirectionsClicked,
}) => {
   const [ref, setRef] = React.useState<HTMLDivElement | null>(null);

   const onClickOutside = () => {
      setSelectedDrive && setSelectedDrive(-1);
   };

   useEffect(() => {
      const handleClickOutside = event => {
         if (ref && !ref.contains(event.target)) {
            setSelectedDrive && setSelectedDrive(-1);
         }
      };
      document.addEventListener("click", handleClickOutside, true);
      return () => {
         document.removeEventListener("click", handleClickOutside, true);
      };
   }, [onClickOutside]);

   return (
      <>
         {drive && (
            <div>
               <InfoWindowF
                  position={{
                     lat: drive!.location.latitude!,
                     lng: drive!.location.longitude!,
                  }}
                  zIndex={111}
                  options={{
                     pixelOffset: new window.google.maps.Size(16, -35),
                     minWidth: 100,
                     // disableAutoPan: true,
                  }}
               >
                  <BloodDriveMarkerPopupWrapper>
                     <BloodDriveMarkerTitle>
                        {drive?.location?.name}
                     </BloodDriveMarkerTitle>
                     <BloodDriveMarkerSubTitle>
                        {drive?.location?.addressOne}
                     </BloodDriveMarkerSubTitle>
                     <BloodDriveMarkerSubTitle className="mt-1">
                        {format(new Date(drive.startTime), "MM/dd/yyyy")}{" "}|{" "}{format(new Date(drive.startTime), "p")}{" "}-{" "}{format(new Date(drive.endTime), "p")}
                     </BloodDriveMarkerSubTitle>
                     <div style={{ display: "flex", flexDirection: "row" }}>
                        <DirectionIcon fill="blue" className="" />
                        <BloodDriveMarkerTitle
                           className="fw-normal"
                           style={{ paddingLeft: 4, color: "blue" }}
                           onClick={onGetDirectionsClicked}>
                           Get Directions
                        </BloodDriveMarkerTitle>
                     </div>
                  </BloodDriveMarkerPopupWrapper>
               </InfoWindowF>
            </div>
         )}
      </>
   );
};

export default BloodDriveMarkerPopup;
