import { useContext, useEffect, useState } from "react";
import { getBloodDriveDataServiceInstance } from "../../../../services/bexWISE/bloodDriveDataService/getBloodDriveDataServiceInstance";
import { IAppointmentType } from "../../../../types/IAppointmentType";
import { IBloodDrive } from "../../../../types/IBloodDrive";
import { IBloodDriveDataService } from "../../../../services/bexWISE/bloodDriveDataService/IBloodDriveDataService";
import { UserContext } from "../../../contexts/user/userContext";

export const formatDateValue = (date: string | Date): string => {
   const dateSent: Date = new Date(date);
   const year: string = dateSent.toLocaleString("default", { year: "numeric" });
   const month: string = dateSent.toLocaleString("default", {
      month: "2-digit",
   });
   const day: string = dateSent.toLocaleString("default", { day: "2-digit" });
   return `${year}-${month}-${day}`;
};
export const useAppointmentData = (drive_id: string, drive_date: string) => {
   const [appointmentData, setAppointmentData] = useState<IBloodDrive | {}>({});
   const [appointmentTypes, setAppointmentTypes] = useState<IAppointmentType[]>(
      [],
   );
   const [appointmentType, setAppointmentType] = useState<string>("");
   const { eligibilityDates } = useContext(UserContext);

   const getAppointmentData = async (): Promise<void> => {
      try {
         const bloodDriveDataService: IBloodDriveDataService =
            getBloodDriveDataServiceInstance();
         const response: IBloodDrive = await bloodDriveDataService.getDrive(
            drive_id,
            drive_date,
         );
         setAppointmentData(response);
         setAppointmentTypes(response.appointmentTypes);
         setAppointmentType(response.appointmentTypes[0].appointmentType);
      } catch (err) {
         throw err;
      }
   };

   useEffect(() => {
      getAppointmentData();
   }, []);

   return {
      appointmentData,
      appointmentTypes,
      appointmentType,
      setAppointmentType,
      eligibilityDates,
   };
};
