import React from "react";
import { format } from "date-fns";
import { ReactComponent as LocationIcon } from "../../../../../assets/svgs/fill-location.svg";
import { OpportunityCardProps } from "./index";
import { HorizontalDivider } from "./styled";
import { getIconForLocationType } from "../../../LocationTypeImageIcon/getIconForLocationType";
const AppointmentTime: React.FC<{
   startTime: Date;
   endTime: Date | undefined;
}> = ({ startTime, endTime }) => {
   if (!endTime) {
      return (
         <p>{`${format(new Date(startTime), "p")} - No End Time Defined`}</p>
      );
   }
   return (
      <p>{`${format(new Date(startTime), "p")} - ${format(
         new Date(endTime!),
         "p",
      )}`}</p>
   );
};

const CardHeader: React.FC<OpportunityCardProps> = ({ details }) => {
   return (
      <div className={"opportunity-card-header"}>
         <div className="d-flex">
            {details.location.distanceFromOriginText && (
               <>
                  <LocationIcon fill="grey" style={{ marginRight: "4px" }} />
                  <div className="d-flex gap-2 miles">
                     <p>
                        {details.location.distanceFromOriginText?.replace(
                           "mi",
                           "miles",
                        )}{" "}
                        away
                     </p>
                     <HorizontalDivider />
                  </div>
               </>
            )}

            {details?.startTime && (
               <AppointmentTime
                  startTime={details.startTime}
                  endTime={details?.endTime}
               />
            )}
         </div>

         {
            <img
               src={getIconForLocationType(details.location.locationType)}
               style={{ width: 20 }}
            />
         }
      </div>
   );
};
export default CardHeader;
