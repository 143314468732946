import styled from "styled-components";
import { Button, Nav } from "react-bootstrap";
import { ReactComponent as PersonVcardIcon } from "../../../../assets/svgs/person-vcard.svg";
import { ReactComponent as GearIcon } from "../../../../assets/svgs/gear.svg";
import { ReactComponent as CompassIcon } from "../../../../assets/svgs/compass-regular.svg";
import { ReactComponent as LogOutIcon } from "../../../../assets/svgs/logout.svg";
import { ReactComponent as ColumnsGapIcon } from "../../../../assets/svgs/columns-gap.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/svgs/search.svg";
import { ReactComponent as CalendarIcon } from "../../../../assets/svgs/calendar_month_solid.svg";
import { ReactComponent as DonationIcon } from "../../../../assets/svgs/donation.svg";
import { ReactComponent as ClipBoardIcon } from "../../../../assets/svgs/clipboard.svg";
import { ReactComponent as CircleUserSolid } from "../../../../assets/svgs/circle-user-solid.svg";
import { ReactComponent as ForwardArrowIcon } from "../../../../assets/svgs/forward-arrow.svg";
import { ReactComponent as PencilSquareIcon } from "../../../../assets/svgs/pencil-square.svg";
import { css } from "styled-components/macro";
import { PRIMARY_COLORS } from "../../../consts/colors";

interface StyledNavDivProps {
   containerWidth: string;
}

export const StyledNavDiv = styled("div")`
   border-right: 1px solid rgba(0, 0, 0, 0.175);
   background-color: white !important;
   box-shadow: 10px 3px 15px 0px rgba(0, 0, 0, 0.28);
   height: 100%;
   left: 0;
   z-index: 666;

   .profile-name-section {
      display: flex;

      align-items: center;
      padding: 16px;
      .up-arrow {
         transform: rotate(270deg);
      }
      .down-arrow {
         transform: rotate(90deg);
      }
   }
   .selected-link {
      background-color: ${PRIMARY_COLORS.HAWKES_BLUE};
   }
`;

export const StyledNav = styled(Nav)`
   display: block;

   .navbar-upper-section {
      padding-top: 20px;
      border-top: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   }
   .navbar-lower-section {
      padding-top: 20px;
      border-top: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   }
   .nav-each-item {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      gap: 10px;
      color: black;
      font-size: 12px;
   }
   #sidebar-wrapper {
      min-height: 100vh !important;
      width: 100vw;
      margin-left: -1rem;
      -webkit-transition: margin 0.25s ease-out;
      -moz-transition: margin 0.25s ease-out;
      -o-transition: margin 0.25s ease-out;
      transition: margin 0.25s ease-out;
   }
   #sidebar-wrapper .sidebar-heading {
      padding: 0.875rem 1.25rem;
      font-size: 1.2rem;
   }

   #page-content-wrapper {
      min-width: 0;
      width: 100%;
   }
`;

const IconImgStyles = css`
   width: 20px;
   height: 20px;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY} !important;
`;
export const PersonVcardImg = styled(PersonVcardIcon)`
   ${IconImgStyles}
`;

export const GearImg = styled(GearIcon)`
   ${IconImgStyles}
`;

export const CompassImg = styled(CompassIcon)`
   ${IconImgStyles}
`;
export const LogOutImg = styled(LogOutIcon)`
   ${IconImgStyles}
`;
styled(ColumnsGapIcon)`
   ${IconImgStyles};
   transform: rotate3d(1, 0, 0, 180deg);
`;
export const SearchImg = styled(SearchIcon)`
   ${IconImgStyles}
`;
export const CalendarImg = styled(CalendarIcon)`
   ${IconImgStyles}
`;
export const DonationImg = styled(DonationIcon)`
   width: 22px;
   height: 22px;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;
export const ClipBoardImg = styled(ClipBoardIcon)`
   ${IconImgStyles};
`;

export const CircleUserSolidImg = styled(CircleUserSolid)`
   width: 50px;
   height: 50px;
   cursor: pointer;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;

export const CircleUserSmallImg = styled(CircleUserSolid)`
   width: 22px;
   height: 22px;
   cursor: pointer;
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
`;
export const ArrowIcon = styled(ForwardArrowIcon)`
   fill: ${PRIMARY_COLORS.BATTLESHIP_GREY};
   margin-left: 5px;
   cursor: pointer;
`;

export const PencilImg = styled(PencilSquareIcon)`
   width: 25px;
   height: 25px;
   transform: rotate(90deg);
`;

export const QuestionnaireButton = styled(Button)`
   span {
      font-size: 12px !important;
   }
   margin-top: 10px;
   margin-left: 10px;
   border-radius: 15px;
   display: flex;
   gap: 10px;
   align-items: center;
   background-color: ${PRIMARY_COLORS.BLUISH} !important;
   border-color: ${PRIMARY_COLORS.BLUISH} !important;
`;
