import React, { useContext, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { ModalPageNames } from "../../../consts";
import { AuthContext, IAuthContext } from "../../../contexts/auth/authContext";
import Form from "react-bootstrap/Form";
import { Code, Password } from "../authComponents";
import {
   useValidCode,
   useValidPassword,
} from "../../../hooks/useAuthValidationHook";
import {
   CheckIconImg,
   CrossIconImg,
   ResendButton,
   ResendButtonGroup,
   SubmitCancelGroup,
   VerificationForm,
   VerificationModalAlert,
   VerificationModalHeader,
} from "./styled";
import { FormGroupAlignedCenter } from "../styled";
import { useMediaQuery } from "react-responsive";

function VerificationComponent({
   emailToBeVerified,
   onClose,
   isPasswordSection,
   setPageName,
}) {
   const { code, setCode, codeIsValid } = useValidCode("");
   const { password, setPassword, passwordIsValid } = useValidPassword("");
   const [passwordVisible, isPasswordVisible] = useState<boolean>(false);
   const [error, setError] = useState<string>("");
   const authContext: IAuthContext = useContext(AuthContext);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const isInValid = (): boolean => {
      if (isPasswordSection) {
         return (
            !codeIsValid ||
            code.length === 0 ||
            !passwordIsValid ||
            password.length === 0
         );
      }
      return !codeIsValid || code.length === 0;
   };

   const submitDetails = async (): Promise<void> => {
      if (isPasswordSection) {
         await confirmPasswordFunc();
      } else {
         await verificationClicked();
      }
   };
   const verificationClicked = async (): Promise<void> => {
      try {
         await authContext.verifyCode(emailToBeVerified, code);
         setPageName(ModalPageNames.SIGN_IN);
      } catch (err: any) {
         setError(err.message);
      }
   };

   const confirmPasswordFunc = async (): Promise<void> => {
      try {
         await authContext.confirmPassword(emailToBeVerified, code, password);
         setPageName(ModalPageNames.SIGN_IN);
      } catch (err: any) {
         setError(err.message);
      }
   };

   const resendCodeFunc = async (): Promise<void> => {
      if (isPasswordSection) {
         await initiateForgotPassword();
      } else {
         await resendCodeConfirmation();
      }
   };
   const resendCodeConfirmation = async (): Promise<void> => {
      try {
         await authContext.resendCode(emailToBeVerified);
      } catch (err: any) {
         setError(err.message);
      }
   };

   const initiateForgotPassword = async (): Promise<void> => {
      try {
         await authContext.forgotPassword(emailToBeVerified);
      } catch (err: any) {
         setError(err);
      }
   };

   const resendCode = (): void => {
      resendCodeFunc();
   };

   return (
      <>
         <Modal.Header></Modal.Header>
         <Modal.Body>
            <VerificationModalHeader>
               <h5>
                  <b>Almost there!</b>
               </h5>
            </VerificationModalHeader>
            {error && (
               <VerificationModalAlert variant="danger">
                  {error}
               </VerificationModalAlert>
            )}
            <VerificationForm
               className={isMobile ? "mobile-css" : "desktop-css"}
            >
               <FormGroupAlignedCenter className="mb-5">
                  A six-digit verification code was sent to:<br></br>
                  <b>{emailToBeVerified}</b>
               </FormGroupAlignedCenter>
               <Form.Group>
                  <Code codeIsValid={codeIsValid} setCode={setCode} />
                  <ResendButtonGroup className="mb-3">
                     <ResendButton variant="link" onClick={resendCode}>
                        Resend Code
                     </ResendButton>
                  </ResendButtonGroup>
               </Form.Group>
               {isPasswordSection && (
                  <Password
                     label="New Password"
                     passwordIsValid={passwordIsValid}
                     setPassword={setPassword}
                     passwordVisible={passwordVisible}
                     isPasswordVisible={isPasswordVisible}
                     isRequired={false}
                     isPasswordShowRequired
                  />
               )}
               <SubmitCancelGroup className="mb-5">
                  <Button
                     onClick={onClose}
                     variant="danger"
                     className="btn-css"
                  >
                     {" "}
                     Cancel <CrossIconImg />
                  </Button>
                  <Button
                     disabled={isInValid()}
                     onClick={submitDetails}
                     variant="danger"
                     className="btn-css"
                  >
                     {" "}
                     Submit <CheckIconImg />
                  </Button>
               </SubmitCancelGroup>
               <FormGroupAlignedCenter>
                  Can't find it? {isMobile && isPasswordSection && <br></br>}
                  {/* Check your spam/junk folder from sender <br></br>
                  <b>noreply@support.com</b> */}

                  {/* Removed email from this modal so the incorrect email address is not being shown to users (AEP-69) */}
                  Check your spam/junk folder.
               </FormGroupAlignedCenter>
            </VerificationForm>
         </Modal.Body>
      </>
   );
}

export default VerificationComponent;
