import React, { useContext } from "react";
import {
   StyledAppointmentCard,
   AppointmentCardHeaderDiv,
   StyledClockIcon,
   StyledGeoLocation,
   StyledTelephoneIcon,
   Footer,
   StyledSearchIcon,
   PhoneNumberSpan,
   StyledBusIcon,
} from "./styled";
import { Body, Header, Title } from "./styled";
import { format } from "date-fns";
import Button from "react-bootstrap/Button";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { EnvironmentContext } from "../../../../contexts/environment/environmentContext";

function formatPhone(phone) {
   if (phone) {
      phone = phone.replace(/\D/g, "");
      if (phone.length == 10) {
         return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
      }
   }
   return null;
}

const AppointmentTime: React.FC<{
   startTime: Date;
   endTime: Date | undefined;
}> = ({ startTime, endTime }) => {
   if (!endTime) {
      return (
         <p>{`${format(new Date(startTime), "p")} - No End Time Defined`}</p>
      );
   }
   return (
      <p>{`${format(new Date(startTime), "p")} - ${format(
         new Date(endTime!),
         "p",
      )}`}</p>
   );
};

const AddressSection = ({ card }) => {
   return (
      <Col>
         Location: {card?.location?.name || ""}
         <br />
         {card?.location?.addressOne},
         {card?.location?.addressTwo && ` ${card?.location?.addressTwo},`}
         <br />
         {card?.location?.city}, {card?.location?.state || ""}{" "}
         {card?.location?.zipCode}
      </Col>
   );
};

const LocationClockSection = ({ card, isMobile }) => {
   return (
      <>
         <div className="align-center-items">
            {card?.location?.distanceFromOriginText && (
               <>
                  <StyledGeoLocation />
                  {card?.location?.distanceFromOriginText} miles away
               </>
            )}
         </div>
         {isMobile && <div>|</div>}
         <div className="align-center-items">
            <StyledClockIcon />
            {card?.startTime ? (
               <AppointmentTime
                  startTime={card.startTime}
                  endTime={card?.endTime}
               />
            ) : (
               <>
                  {`${format(new Date(), "p")} -
                                 ${format(new Date(), "p")}`}
               </>
            )}
         </div>
      </>
   );
};

const PhoneNumberSection = ({ card }) => {
   return (
      <Col className="phone-number-section">
         <div className="align-center-items">
            <StyledTelephoneIcon fill="gray" />
            <PhoneNumberSpan>
               {formatPhone(card?.location?.phoneNumber)}
            </PhoneNumberSpan>
         </div>
      </Col>
   );
};

function AppointmentCard({ card }) {
   const navigate = useNavigate();
   const isMobile = useMediaQuery({ maxWidth: 767 });
   const { centerData } = useContext(EnvironmentContext);
   return (
      <StyledAppointmentCard>
         <Header>
            <AppointmentCardHeaderDiv>
               {card?.location?.locationType?.isMobile && <StyledBusIcon />}
               <Title>{card?.account?.accountName}</Title>
            </AppointmentCardHeaderDiv>
            {!isMobile && (
               <Button
                  variant="danger"
                  className="change-location-button"
                  onClick={() => navigate("/")}
               >
                  Change Location <StyledSearchIcon className="search-icon" />
               </Button>
            )}
         </Header>
         <Body className={isMobile ? "mobile-css" : "desktop-css"}>
            {isMobile ? (
               <>
                  <AddressSection card={card} />
                  <PhoneNumberSection card={card} />
                  <div className="location-clock-mobile-css">
                     <LocationClockSection card={card} isMobile={isMobile} />
                  </div>
               </>
            ) : (
               <>
                  <AddressSection card={card} />
                  <Col>
                     <LocationClockSection card={card} isMobile={isMobile} />
                  </Col>
                  <PhoneNumberSection card={card} />
               </>
            )}
         </Body>
         <Footer className={isMobile ? "mobile-css" : "desktop-css"}>
            <div>
               {/* 'Visit Our Page' should open in new tab (AEP-48) */}
               <a className="visit-page-css" href={`${centerData?.contact}`} target="_blank">
                  <b>Questions?</b> Contact us
               </a>
            </div>
            {isMobile && (
               <Button
                  variant="danger"
                  className="change-location-button"
                  onClick={() => navigate("/")}
               >
                  Change Location <StyledSearchIcon className="search-icon" />
               </Button>
            )}
         </Footer>
      </StyledAppointmentCard>
   );
}

export default AppointmentCard;
