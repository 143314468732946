import styled from "styled-components";
import { ReactComponent as Search } from "assets/svgs/search.svg";
import { ReactComponent as Aim } from "assets/svgs/aim.svg";
import Form from "react-bootstrap/Form";

export const SearchIcon = styled(Search)`
   top: 14px;
   left: 10px;
   position: absolute;
`;

export const AimIcon = styled(Aim)`
   top: 14px;
   right: 20px;
   position: absolute;
   width: 20px;
   height: 20px;
`;

export const FormInput = styled(Form.Control)`
   background: none;
   padding: 0.375rem 0.75rem 0.55rem 1.95rem;
   height: 50px;
`;
