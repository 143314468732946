import React, { ReactElement } from "react";
import { Col, Row } from "react-bootstrap";
import Map from "app/components/OpportunitySearchComponent/components/OpportunityMap/Map";
export interface IOpportunityMapProps {}

export function OpportunityMap(
   props: IOpportunityMapProps,
): ReactElement<IOpportunityMapProps> {
   return (
      <Row id={"opportunity-map-row"}>
         <Col xs={12}>
            <Map />
         </Col>
      </Row>
   );
}
