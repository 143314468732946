import React, { ReactElement } from "react";
import { IconTextControl } from "../../Generic/IconTextControl/IconTextControl";
import MoreFilterIcon from "../../../../../../assets/images/more-filters.png";
import { FilterControlWrapper } from "../../../Filter/FilterControlWrapper";

export interface IMoreFiltersIconProps {
   onClick: () => void;
}

export function MoreFiltersIconControl({
   onClick,
}: IMoreFiltersIconProps): ReactElement<IMoreFiltersIconProps> {
   return (
      <IconTextControl
         imgSrc={MoreFilterIcon}
         text={"More Filters"}
         onClick={onClick}
      />
   );
}
