import styled from "styled-components";

export const SectionWrapper = styled("div")`
   margin-top: 20px;
   @media (max-width: 991px) {
      .mobile-order {
         order: 2;
      }
   }

   @media (max-width: 991px) {
      .map-wrapper {
         height: 40vh;
         margin-bottom: 20px;
      }
   }
`;

export const BasicLayoutOpportunityListWrapper = styled.div`
   height: 485px;
   display: flex;
   flex-direction: column;
   gap: 8px;
   padding: 2px;
   overflow-y: scroll;
`;

export const Footer = styled("div")`
   display: flex;
   align-items: center;
   margin-top: 20px;
`;
