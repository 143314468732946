import styled from "styled-components";
import Container from "react-bootstrap/Container";
import { ReactComponent as ArrowLeftIcon } from "../../../assets/svgs/arrow-left.svg";
import { ReactComponent as ExclamationIcon } from "../../../assets/svgs/exclamation-circle-fill.svg";
import { PRIMARY_COLORS } from "../../consts/colors";

export const AppointmentListOuterContainer = styled.div`
   //  margin-top: 80px;
   position: relative;
   display: flex;
   font-family: "Liberation Sans", sans-serif !important;

   width: 100%;
   .arrow-section {
      width: 30px;
      height: 30px;
      background-color: ${PRIMARY_COLORS.AQUA_HAZE};
      border: 1px solid ${PRIMARY_COLORS.LAVENDER_MIST};
      border-radius: 50%;
      cursor: pointer;
      text-align: center;
   }
   &.medium-screen-css {
      // margin-left: 100px;
      //  margin-right: 15px;
      .arrow-section {
         position: absolute;
         left: 120px;
      }
   }
   &.mobile-version-css {
      .arrow-section {
         margin-bottom: 20px;
         margin-left: 20px;
      }
   }
`;
export const ArrowLeftImg = styled(ArrowLeftIcon)`
   fill: ${PRIMARY_COLORS.DOVE_GREY};
   cursor: pointer;
`;

export const ExclamationImg = styled(ExclamationIcon)`
   fill: ${PRIMARY_COLORS.FLUSH_MAHOGANY};
   width: 30px;
   height: 30px;
`;

export const AppointmentListContainer = styled(Container)`
   padding-bottom: 10px;
   margin-top: 30px;
   &.mobile-version-css {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
      padding-left: 0px;
      padding-right: 0px;
      .alert-msg-css {
         display: flex;
         flex-direction: column;
         gap: 10px;
      }
      .appointment-text {
         margin: 20px;
      }
      .bottom-section-css {
         margin-left: 10px;
         margin-right: 10px;
      }
   }
   &.medium-screen-css {
      width: 60%;
      .alert-msg-css {
         display: flex;
         gap: 20px;
      }
   }
   .no-appointment-class {
      font-size: 14px;
      color: gray;
      margin-left: 10px;
   }
   .alert-msg-css {
      font-size: 14px;
      align-items: center;
      background-color: ${PRIMARY_COLORS.COSMOS};
   }
   .appointment-text {
      font-size: 14px;
   }
`;
