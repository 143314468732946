import styled from "styled-components";
import { ReactComponent as ForwardArrowIcon } from "../../../../../assets/svgs/forward-arrow.svg";
import { css } from "styled-components/macro";
import { PRIMARY_COLORS } from "../../../../consts/colors";

const baseSectionMargin = css`
   &.margin-right-selected {
      margin-right: 7%;
   }
   &.margin-left-selected {
      margin-left: 7%;
   }
`;

export const AppointmentTimeDiv = styled.div`
   border: 1px solid black;
   border-radius: 3px;
   height: 30px;
   display: flex;
   align-items: center;
   justify-content: center;
   font-size: 12px;
   &.disable-time {
      pointer-events: none;
      color: darkgray;
   }
   &.enable-time {
      cursor: pointer;
      font-weight: 600;
   }
   &.selected {
      background-color: ${PRIMARY_COLORS.LAVA_RED};
      color: white;
   }
`;

export const AppointmentTimeSectionDiv = styled.div`
   display: grid;
   grid-template-columns: auto auto auto auto;
   gap: 4px;
   ${baseSectionMargin}
`;

export const AppointmentTimeLabel = styled.label`
   ${baseSectionMargin}
`;

export const DownArrowIcon = styled(ForwardArrowIcon)`
   rotate: 90deg;
   width: 12px;
   height: 12px;
   margin-left: 7px;
   margin-right: 30px;
   cursor: pointer; ;
`;
