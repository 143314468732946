import React from "react";
import { ReactComponent as ArrowIcon } from "../../../../../assets/svgs/forward-arrow.svg";
import { Body, SubTitle, Title } from "./styled";
import { ILocation } from "types/ILocation";

interface CardBodyProps {
   details: ILocation;
}

const CardBody: React.FC<CardBodyProps> = ({ details }) => {
   return (
      <Body>
         <div>
            <Title>{details.name}</Title>
            <SubTitle>
               {details.addressOne} {details.addressTwo} <br />
               {details.city} {details.state} {details.zipCode}
            </SubTitle>
         </div>
         <ArrowIcon fill="gray" />
      </Body>
   );
};

export default CardBody;
