import React from "react";
import { Col, Row } from "react-bootstrap";
import { StyledAppointmentRow } from "./styled";
import { AppointmentsDropdown } from "../AppointmentsDropdown";

function AppointmentType({
   appointmentTypes,
   appointmentType,
   setAppointmentType,
}) {
   return (
      <StyledAppointmentRow as={Row} className="mb-5">
         <Col
            xs={appointmentTypes.length > 1 ? "12" : "6"}
            sm={4}
            className={appointmentTypes.length > 1 ? "mb-2" : ""}
            md={3}
         >
            <b>Appointment Type{appointmentTypes.length === 1 && ":"}</b>
         </Col>
         <Col xs={appointmentTypes.length > 1 ? "12" : "6"} sm={6} md={4}>
            {appointmentTypes.length > 1 ? (
               <AppointmentsDropdown
                  list={appointmentTypes}
                  name="WB"
                  value={appointmentType}
                  handleChange={setAppointmentType}
               />
            ) : (
               <span>
                  <b>{appointmentTypes[0]?.appointmentTypeDescription}</b>
               </span>
            )}
         </Col>
      </StyledAppointmentRow>
   );
}

export default AppointmentType;
