import styled from "styled-components";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { ReactComponent as CrossIconLabel } from "../../../../assets/svgs/cross.svg";
import { ReactComponent as CheckMarkLabel } from "../../../../assets/svgs/check-solid.svg";
import { IconsCss } from "../styled";
import Alert from "react-bootstrap/Alert";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const VerificationModalHeader = styled.header`
   display: flex;
   justify-content: center;
`;

export const VerificationModalAlert = styled(Alert)`
   margin-top: 20px;
`;
export const VerificationForm = styled(Form)`
   &.desktop-css {
      padding: 35px 50px 0 50px;
   }
   &.mobile-css {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      height: 90%;
   }
   font-size: 13px;
`;

export const ResendButtonGroup = styled(Form.Group)`
   display: flex;
   justify-content: flex-end;
`;

export const ResendButton = styled(Button)`
   color: ${PRIMARY_COLORS.FIRE_ENGINE_RED} !important;
   font-size: 12px;
   text-decoration: none;
`;

export const SubmitCancelGroup = styled(Form.Group)`
   display: grid;
   grid-template-columns: 45% 45%;
   grid-gap: 10%;
   margin-top: 50px;
   .btn-css {
      font-size: 12px;
   }
`;
export const CrossIconImg = styled(CrossIconLabel)`
   ${IconsCss};
   float: right;
`;
export const CheckIconImg = styled(CheckMarkLabel)`
   ${IconsCss};
   float: right;
`;
