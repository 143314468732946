import React from "react";
import { IAppointmentType } from "types/IAppointmentType";
import { Footer, Span } from "./styled";
import { v4 as uuidv4 } from "uuid";
import { ConvertStringToPascalCase } from "utils/convertStringToPascalCase";

import { ReactComponent as DropIcon } from "../../../../../assets/svgs/drop.svg";
interface CardFooterProps {
   availability: IAppointmentType[] | [];
   count: number;
}

const DetailAvailabilties: React.FC<{
   appointment: IAppointmentType;
   totalLength: number;
   index: number;
}> = ({ appointment, totalLength, index }) => {
   return (
      <React.Fragment key={uuidv4().toString()}>
         <Span className="px-1" total={appointment?.appointmentsRemaining}>
            {`${appointment?.appointmentsRemaining}
                ${ConvertStringToPascalCase(
               appointment?.appointmentTypeDescription,
            )}`}
            {index < totalLength - 1 && ","}
         </Span>
      </React.Fragment>
   );
};

const CardFooter: React.FC<CardFooterProps> = ({ availability, count }) => {
   if (!count) return <b style={{ fontSize: "14px" }}>DRIVE FULL</b>;
   return (
      <Footer className="d-flex align-items-center">
         <DropIcon fill="red" style={{ marginRight: "4px" }} />
         <p>
            {count > 10 && (
               <>
                  <b>Available :</b>
                  <Span className="px-1" total={count}>
                     {count} (
                     {availability?.map(
                        (appointment, ind) =>
                           ConvertStringToPascalCase(
                              appointment.appointmentTypeDescription,
                           ) + `${ind < availability.length - 1 ? ", " : ""}`,
                     )}
                     )
                  </Span>
               </>
            )}

            {count < 10 &&
               availability?.map(
                  (appointment: IAppointmentType, index: number) => (
                     <DetailAvailabilties
                        appointment={appointment}
                        totalLength={availability.length}
                        index={index}
                        key={index}
                     />
                  ),
               )}
         </p>
      </Footer>
   );
};

export default CardFooter;
