import styled from "styled-components";

export const Div = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   border: 1px solid gray;
   width: 100%;
   border-radius: 10px;
   padding: 8px 10px;
   min-width: 120px;
   align-items: center;
   gap: 10px;
   cursor: pointer;
`;
