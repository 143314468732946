import React, { useContext } from "react";
import { useFormikContext } from "formik";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import {
   ChevronUpButtonContainer,
   ChevronDownButtonContainer,
   ChevronDownIcon,
   ChevronUpIcon,
} from "./styled";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../../OpportunitySearchComponent/context/OpportunitySearchContext";

interface FormInputCounterProps {
   name: string;
   placeholder?: string;
}

const FormInputCounter: React.FC<FormInputCounterProps> = ({ name }) => {
   const {
      filters: { radiusMiles },
      updateFilter,
   } = useContext<IOpportunitySearchContext>(OpportunitySearchContext);
   const handleIncrease = () => {
      switch (Number(radiusMiles)) {
         case 5:
            updateFilter({ radiusMiles: "10" });
            break;

         case 10:
            updateFilter({ radiusMiles: "25" });
            break;
         default:
            return;
      }
   };

   const handleDown = () => {
      switch (Number(radiusMiles)) {
         case 5:
            updateFilter({ radiusMiles: "5" });
            break;

         case 10:
            updateFilter({ radiusMiles: "5" });
            break;

         case 25:
            updateFilter({ radiusMiles: "10" });
            break;
         default:
            break;
      }
   };

   return (
      <InputGroup style={{ zIndex: 0 }}>
         <Form.Control
            placeholder="Miles"
            readOnly
            value={`${radiusMiles} Miles`}
         />
         <div className="d-flex flex-column">
            <ChevronUpButtonContainer
               variant="outline-secondary"
               onClick={handleIncrease}
            >
               <ChevronUpIcon />
            </ChevronUpButtonContainer>
            <ChevronDownButtonContainer
               variant="outline-secondary"
               onClick={handleDown}
            >
               <ChevronDownIcon />
            </ChevronDownButtonContainer>
         </div>
      </InputGroup>
   );
};

export default FormInputCounter;
