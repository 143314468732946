import React, { useEffect, useState } from "react";
import SignInOrNewAppointment from "../SignInOrNewAppointment";
import SignInComponent from "../SignInComponent";
import NewAccount from "../NewAccount";
import VerificationComponent from "../VerficationComponent";
import { ModalPageNames } from "../../../consts";
import RequestAppointment from "../RequestAppointment";
import { AuthenticationModal } from "./styled";
import ForgetPassword from "../ForgetPassword";
import { useMediaQuery } from "react-responsive";
import AppointmentConfirmationModal from "../AppointmentConfirmationModal";
import ChangePasswordModal from "../ChangePassword";

interface IModalInterface {
   show: boolean;
   setModalShow: (_: boolean) => void;
   pageNameSent: string;
   confirmedAptDetails?: any;
   setIsCancelConfirmed?: (_: boolean) => void;
}
export const SignInModal: React.FunctionComponent<IModalInterface> = ({
   show,
   setModalShow,
   pageNameSent,
   confirmedAptDetails,
   setIsCancelConfirmed,
}: IModalInterface) => {
   const [pageName, setPageName] = useState<string>("");
   const [modalSize, setModalSize] = useState<"sm" | "lg" | "xl" | undefined>(
      undefined,
   );
   const [emailToBeVerified, setEmailToBeVerified] = useState<string>("");
   const [isPasswordSection, setIsPasswordSection] = useState<boolean>(false);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const onClose = (): void => {
      setModalShow(false);
   };

   const onOpenModal = (): void => {
      setPageName(pageNameSent);
      setModalSize(undefined);
   };

   useEffect(() => {
      switch (pageName) {
         case ModalPageNames.INITIAL:
            setModalSize(undefined);
            break;
         case ModalPageNames.NEW_ACCOUNT:
            setModalSize("lg");
            break;
         case ModalPageNames.REQUEST_APPOINTMENT:
            setModalSize("lg");
            break;
         default:
            setModalSize(undefined);
      }

      if (pageName === ModalPageNames.SIGN_IN) {
         setEmailToBeVerified("");
         setIsPasswordSection(false);
      }
   }, [pageName]);

   return (
      <AuthenticationModal
         size={modalSize}
         show={show}
         onHide={onClose}
         onEnter={onOpenModal}
         aria-labelledby="contained-modal-title-vcenter"
         backdrop="static"
         keyboard={false}
         fullscreen={isMobile}
         className={isMobile ? "mobile-css" : "desktop-css"}
         centered
      >
         <div className="top-modal-border"></div>
         {pageName === ModalPageNames.INITIAL && (
            <SignInOrNewAppointment setPageName={setPageName} />
         )}
         {pageName === ModalPageNames.SIGN_IN && (
            <SignInComponent
               setPageName={setPageName}
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
            />
         )}
         {pageName === ModalPageNames.REQUEST_APPOINTMENT && (
            <RequestAppointment
               onClose={onClose}
               confirmedAptDetails={confirmedAptDetails!}
            />
         )}
         {pageName === ModalPageNames.FORGET_PASSWORD && (
            <ForgetPassword
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
               setPageName={setPageName}
               setIsPasswordSection={setIsPasswordSection}
            />
         )}
         {pageName === ModalPageNames.NEW_ACCOUNT && (
            <NewAccount
               setPageName={setPageName}
               onClose={onClose}
               setEmailToBeVerified={setEmailToBeVerified}
               setIsPasswordSection={setIsPasswordSection}
            />
         )}
         {pageName === ModalPageNames.VERIFYING_ACCOUNT && (
            <VerificationComponent
               emailToBeVerified={emailToBeVerified}
               onClose={onClose}
               isPasswordSection={isPasswordSection}
               setPageName={setPageName}
            />
         )}
         {pageName === ModalPageNames.APPOINTMENT_CONFIRMATION && (
            <AppointmentConfirmationModal
               onClose={onClose}
               confirmedAptDetails={confirmedAptDetails}
               setIsCancelConfirmed={setIsCancelConfirmed}
            />
         )}
         {pageName === ModalPageNames.CHANGE_PASSWORD && (
            <ChangePasswordModal onClose={onClose} />
         )}
      </AuthenticationModal>
   );
};

export default SignInModal;
