import styled from "styled-components";
import { Modal } from "react-bootstrap";
import { PRIMARY_COLORS } from "../../../consts/colors";

export const AuthenticationModal = styled(Modal)`
   .modal-content {
      font-family: "Liberation Sans", sans-serif;
      border-top: none;
      border-radius: 0.25rem;
      .top-modal-border {
         width: 100%;
         height: 5px;
         background-color: ${PRIMARY_COLORS.FIRE_ENGINE_RED};
         border-radius: 0.5rem;
      }
      .modal-header {
         border-bottom: none;
      }
   }
   &.desktop-css {
      .modal-body {
         margin: 0px 20px 20px 20px;
      }
   }
`;
