import * as React from "react";
import { PageComponent } from "../../components/PageComponent";
import {
   NavigateFunction,
   useNavigate,
   useSearchParams,
} from "react-router-dom";
import {
   formatDateValue,
   useAppointmentData,
} from "./hooks/useAppointmentData";
import { useEffect, useState } from "react";
import {
   AppointmentDataContext,
   IAppointmentDataContext,
} from "./context/AppointmentDataContext";
import {
   IDriveAppointmentAvailability,
   IDriveAppointmentAvailabilityTimes,
} from "../../../services/bexWISE/bloodDriveDataService/IBloodDriveDataService";
import { IUserContext, UserContext } from "../../contexts/user/userContext";
import { AuthContext, IAuthContext } from "../../contexts/auth/authContext";
import { getBloodDriveDataServiceInstance } from "../../../services/bexWISE/bloodDriveDataService/getBloodDriveDataServiceInstance";
import { AuthStatus } from "../../../services/cognitoService/AuthStatus";
import { useMediaQuery } from "react-responsive";
import {
   AppointmentListContainer,
   AppointmentListOuterContainer,
   ArrowLeftImg,
   ExclamationImg,
} from "./styled";
import { ROUTE_PATHS } from "../../consts/RoutePaths";
import { Alert, Stack } from "react-bootstrap";
import AppointmentCard from "./components/AppointmentCard";
import HorizontalCalendar from "./components/HorizontalCalendar";
import AppointmentType from "./components/AppointmentType";
import AppointmentTimes from "./components/AppointmentTimesSection";
import ConfirmAppointmentCard from "./components/ConfirmAppointmentCard";
import { AppointmentText } from "./components/AppointmentText/AppointmentText";

function formatDate(date: Date): string {
   const options: Intl.DateTimeFormatOptions = {
      month: "short",
      day: "numeric",
      year: "numeric",
   };
   return date.toLocaleDateString("en-US", options);
}

function getAppointmentTimesDisplayed(
   appointmentTimes: IDriveAppointmentAvailability[],
   appointmentType: string,
): IDriveAppointmentAvailabilityTimes[] {
   if (Array.isArray(appointmentTimes) && appointmentTimes.length) {
      const specificAptTimes: IDriveAppointmentAvailabilityTimes[] | undefined =
         appointmentTimes.find(
            each =>
               each.appointmentTypeSummary.appointmentType === appointmentType,
         )?.appointmentTimes;
      return specificAptTimes || [];
   } else {
      return [];
   }
}

export function AppointmentListPage() {
   const [searchParams] = useSearchParams();
   const drive_id: string = searchParams.get("drive_id") || "";
   const drive_date: string = searchParams.get("date") || "";
   const {
      appointmentData = {},
      appointmentTypes,
      appointmentType,
      setAppointmentType,
      eligibilityDates,
   } = useAppointmentData(drive_id, drive_date);
   const [selectedDate, setSelectedDate] = React.useState<Date | "">("");
   const [context, setContext] = useState<IAppointmentDataContext>({});
   const [appointmentTimes, setAppointmentTimes] = useState<
      IDriveAppointmentAvailability[]
   >([]);
   const [appointmentTimesDisplayed, setAppointmentTimesDisplayed] = useState<
      IDriveAppointmentAvailabilityTimes[] | []
   >([]);
   const [eligibleDateForSelectedType, setEligibleDateForSelectedType] =
      useState<string | undefined>("");
   const [isNotEligible, setIsNotEligible] = useState<boolean>(false);
   const [timeSelected, setTimeSelected] = useState<string>("");
   const [timeSlotSelectedId, setTimeSlotSelectedId] = useState<number>(0);
   const [reschedulingAppointmentId, setReschedulingAppointmentId] =
      useState<string>("");
   const { profileData } = React.useContext<IUserContext>(UserContext);
   const { authStatus } = React.useContext<IAuthContext>(AuthContext);
   const navigate: NavigateFunction = useNavigate();
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   useEffect(() => {
      const reSchAptId: string | null = sessionStorage.getItem(
         "ReschedulingAppointmentId",
      );
      if (reSchAptId) {
         setReschedulingAppointmentId(reSchAptId);
         sessionStorage.removeItem("ReschedulingAppointmentId");
      }
   }, []);

   useEffect(() => {
      if (eligibilityDates?.length) {
         const eligibleDate: string | undefined = eligibilityDates.find(
            each => each?.appointmentType === appointmentType,
         )?.eligibleDate;
         setEligibleDateForSelectedType(eligibleDate);
      }
   }, [eligibilityDates, appointmentType]);

   useEffect(() => {
      if (authStatus === AuthStatus.SignedOut) {
         setIsNotEligible(false);
      } else {
         if (eligibleDateForSelectedType && selectedDate) {
            const eligibleDate: Date = new Date(eligibleDateForSelectedType);
            const selectDate: Date = new Date(selectedDate);
            if (eligibleDate.getTime() > selectDate.getTime()) {
               setIsNotEligible(true);
            } else {
               setIsNotEligible(false);
            }
         } else {
            setIsNotEligible(true);
         }
      }
   }, [eligibleDateForSelectedType, selectedDate, authStatus]);

   useEffect(() => {
      if (selectedDate) {
         getBloodDriveDataServiceInstance()
            .getDriveAppointments(drive_id, formatDateValue(selectedDate))
            .then((availability: IDriveAppointmentAvailability[]) => {
               setAppointmentTimes(availability);
            });
      }
      setTimeSelected("");
   }, [selectedDate]);

   useEffect(() => {
      setTimeSelected("");
      setAppointmentTimesDisplayed(
         getAppointmentTimesDisplayed(appointmentTimes, appointmentType),
      );
   }, [appointmentType, appointmentTimes]);

   const getAppointmentNameDescription = (
      appointmentType: string,
   ): string | undefined => {
      if (
         appointmentTypes &&
         Array.isArray(appointmentTypes) &&
         appointmentTypes.length > 0
      ) {
         return appointmentTypes.find(
            each => each.appointmentType === appointmentType,
         )?.appointmentTypeDescription;
      } else {
         return "";
      }
   };

   return (
      <PageComponent
         name={"AppointmentDataPage"}
         title={"Schedule Appointment"}
         description={"Appointment Data Page"}
      >
         <AppointmentDataContext.Provider value={context}>
            <AppointmentListOuterContainer
               className={`${
                  isMobile ? "mobile-version-css" : "medium-screen-css"
               }`}
            >
               {authStatus === AuthStatus.SignedIn && (
                  <div
                     className="arrow-section"
                     onClick={() => navigate(ROUTE_PATHS.MY_APPOINTMENTS)}
                  >
                     <ArrowLeftImg />
                  </div>
               )}
               <AppointmentListContainer
                  className={
                     isMobile ? "mobile-version-css" : "medium-screen-css"
                  }
               >
                  <Stack gap={3}>
                     <AppointmentCard card={appointmentData} />
                     <AppointmentText
                        isSingleAppointmentType={appointmentTypes.length === 1}
                     />
                     <HorizontalCalendar
                        driveDate={drive_date}
                        setSelectedDate={setSelectedDate}
                     />
                     {appointmentTimes.length > 0 && appointmentType ? (
                        <div className="bottom-section-css">
                           <AppointmentType
                              appointmentTypes={appointmentTypes}
                              appointmentType={appointmentType}
                              setAppointmentType={setAppointmentType}
                           />
                           {isNotEligible && profileData && (
                              <Alert variant="danger" className="alert-msg-css">
                                 <ExclamationImg />
                                 <div>
                                    <b>
                                       {profileData?.firstName || ""},
                                       unfortunately, you are not eligible to
                                       donate{" "}
                                       {getAppointmentNameDescription(
                                          appointmentType,
                                       )}
                                       {eligibleDateForSelectedType ? (
                                          <>
                                             {" "}
                                             until{" "}
                                             {eligibleDateForSelectedType &&
                                                formatDate(
                                                   new Date(
                                                      eligibleDateForSelectedType,
                                                   ),
                                                )}
                                             .
                                          </>
                                       ) : (
                                          <>.</>
                                       )}
                                    </b>{" "}
                                    <br />
                                    {isMobile && <br />}
                                    Please try a different appointment type,
                                    select a different day, or change your
                                    location.
                                 </div>
                              </Alert>
                           )}
                           {appointmentTimesDisplayed &&
                              appointmentTimesDisplayed.length > 0 && (
                                 <AppointmentTimes
                                    appointmentTimesDisplayed={
                                       appointmentTimesDisplayed
                                    }
                                    timeSelected={timeSelected}
                                    setTimeSelected={setTimeSelected}
                                    isNotEligible={isNotEligible}
                                    setTimeSlotSelectedId={
                                       setTimeSlotSelectedId
                                    }
                                    card={appointmentData}
                                 />
                              )}
                           {timeSelected && (
                              <ConfirmAppointmentCard
                                 card={appointmentData}
                                 appointmentTypeDisplayed={getAppointmentNameDescription(
                                    appointmentType,
                                 )}
                                 appointmentType={appointmentType}
                                 timeSelected={timeSelected}
                                 selectedDate={selectedDate}
                                 drive_id={drive_id}
                                 timeSlotSelectedId={timeSlotSelectedId}
                                 isNotEligible={isNotEligible}
                                 eligibleDateForSelectedType={
                                    eligibleDateForSelectedType &&
                                    formatDate(
                                       new Date(eligibleDateForSelectedType),
                                    )
                                 }
                                 reschedulingAppointmentId={
                                    reschedulingAppointmentId
                                 }
                              />
                           )}
                        </div>
                     ) : (
                        <>
                           <div className="no-appointment-class">
                              No appointments available for this date.
                           </div>
                        </>
                     )}
                  </Stack>
               </AppointmentListContainer>
            </AppointmentListOuterContainer>
         </AppointmentDataContext.Provider>
      </PageComponent>
   );
}
