import styled from "styled-components";

interface CardHeaderProps {
   bg?: string;
}

export const CardHeader = styled("header")<CardHeaderProps>`
   width: 100%;
   height: 45px;
   display: flex;
   gap: 20px;
   align-items: center;
   justify-content: space-between;
   padding: 13px 30px;
   border-top-left-radius: 4px;
   border-top-right-radius: 4px;
   background: ${props => props.bg};

   h4 {
      color: white;
      font-size: 12px;
      font-weight: bold;
   }
`;
