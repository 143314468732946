import React, { ReactElement } from "react";
import { InputWrapper } from "../../InputWrapper/InputWrapper";
import { ISelectableOption } from "./ISelectableOption";
import "./style.scss";
import { FilterControlWrapper } from "../../../Filter/FilterControlWrapper";
import { Dropdown, DropdownChangeParams } from "primereact/dropdown";
export interface ISingleSelectProps<T> {
   values: ISelectableOption<T>[];
   selectedValue: ISelectableOption<T>;
   onValueSelected: (selectedValue: ISelectableOption<T>) => void;
   border?: boolean;
}

export function SingleSelect<T>({
   values,
   selectedValue,
   onValueSelected,
   border = true,
}: ISingleSelectProps<T>): ReactElement {
   return (
      <FilterControlWrapper>
         <InputWrapper>
            <div
               className={`input single-select ${
                  border ? "show-border" : "no-border"
               }`}
            >
               <div className={"input-label-container"}>
                  {selectedValue && selectedValue.iconImgSrc && (
                     <div className={"input-label-image-container"}>
                        <img
                           className={"input-label-image"}
                           src={selectedValue.iconImgSrc}
                        />
                     </div>
                  )}
                  <Dropdown
                     value={selectedValue.value}
                     options={values}
                     onChange={(e: DropdownChangeParams) => {
                        onValueSelected(
                           values.find(value => value.value === e.value)!,
                        );
                     }}
                  />
               </div>
            </div>
         </InputWrapper>
      </FilterControlWrapper>
   );
}
