import React, { useEffect, useState } from "react";
import { AuthStatus } from "services/cognitoService/AuthStatus";
import { useMediaQuery } from "react-responsive";
import { AuthContext } from "app/contexts/auth/authContext";
import { LayoutContext } from "../context/layoutContext";
import { Header } from "../../components/Header/Header";
import Footer from "../../components/Footer";
import { Navbar } from "../../components/Navbar/Navbar";
import "./style.scss";
import { Outlet } from "react-router-dom";

const DashboardLayout = () => {
   const { authStatus } = React.useContext(AuthContext);
   const [isNavBarExpanded, setIsNavBarExpanded] = useState<boolean>(false);
   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });
   const isSignedIn: boolean = authStatus === AuthStatus.SignedIn;

   useEffect(() => {
      if (isMobile && isNavBarExpanded) {
         setIsNavBarExpanded(false);
      }
   }, [isMobile]);
   return (
      <LayoutContext.Provider
         value={{ isMobile, isSignedIn, isNavBarExpanded, setIsNavBarExpanded }}
      >
         <div id={"layout"}>
            <Header />
            <div id={"layout-content"}>
               {isSignedIn && <Navbar />}
               <div id={"layout-main"}>
                  <div id={"layout-outlet"}>
                     <Outlet />
                  </div>
                  {!isMobile && <Footer />}
               </div>
            </div>
         </div>
      </LayoutContext.Provider>
   );
};

export default DashboardLayout;
