import React from "react";
import { Image } from "react-bootstrap";
import MapIcon from "assets/images/map-icon.png";
import "./appointmentSlot.scss";
import {
   AppointmentSlotStatusBadge,
   IAppointmentSlotStatusProps,
} from "./AppointmentSlotStatusBadge";
interface IAppointmentSlotProps {
   month: string;
   day: string;
   iconSrc: string;
   name: string;
   subTitle: string;
   rightTopIcon?: JSX.Element;
   rightTopLabel?: string;
   rightTopValue: string;
   rightBottomLabel: string;
   rightBottomValue: string;
   status?: IAppointmentSlotStatusProps;
   mapDirections?: {
      from: string;
      to: string;
   };
   actionButtons?: {
      imageSrc: string;
      onClick: () => void;
      text: string;
   }[];
}
// Shared for future and past appointments
export function AppointmentSlot(props: IAppointmentSlotProps) {
   const {
      month,
      day,
      iconSrc,
      name,
      subTitle,
      rightTopLabel,
      rightTopValue,
      rightTopIcon,
      rightBottomLabel,
      rightBottomValue,
      status,
      mapDirections,
      actionButtons,
   } = props;
   return (
      <div className={"appointment-slot"}>
         <div className={"date"}>
            <p className={"month"}>{month}</p>
            <p className={"day"}>{day}</p>
         </div>
         <div className={"detail-section"}>
            <div className={"left-section"}>
               <p className={"title"}>
                  <Image src={iconSrc} />
                  <span>{name}</span>
               </p>
               <p className={"sub-title"}>
                  <span>{subTitle}</span>
               </p>
            </div>
            <div className={"right-section"}>
               <div className={"labels"}>
                  <p className="sub-title">
                     {rightTopIcon && rightTopIcon}
                     {rightTopLabel && `${rightTopLabel}: `}
                     <b>{rightTopValue}</b>
                  </p>
                  <p className="sub-title">
                     {rightBottomLabel}: <b>{rightBottomValue}</b>
                  </p>
               </div>
               {mapDirections && (
                  <div
                     className={"map-directions"}
                     onClick={() => {
                        const origin: string = mapDirections.from;
                        const destination: string = mapDirections.to;
                        window.open(
                           `https://www.google.com/maps/dir/?api=1&origin=${origin}&destination=${destination}&travelmode=car`,
                        );
                     }}
                  >
                     <Image src={MapIcon} />
                  </div>
               )}
            </div>
         </div>

         {status && (
            <AppointmentSlotStatusBadge type={status.type} text={status.text} />
         )}

         {actionButtons && (
            <div className={"actions"}>
               {actionButtons.map((actionButton, index) => (
                  <button onClick={actionButton.onClick} key={index}>
                     <Image src={actionButton.imageSrc} />
                     {actionButton.text}
                  </button>
               ))}
            </div>
         )}
      </div>
   );
}
