import React, { ReactElement, useContext } from "react";
import { Col, Row } from "react-bootstrap";
import "./style.scss";
import { OpportunitySearchBar } from "../OpportunitySearchBar/OpportunitySearchBar";
import { OpportunitySearchBarView } from "../OpportunitySearchBar/views/OpportunitySearchBarView";
import { OpportunitySearchBarLandingView } from "../OpportunitySearchBar/views/OpportunitySearchBarLandingView";
import { OpportunitySearchBarFullScreenView } from "../OpportunitySearchBar/views/OpportunitySearchBarFullScreenView";
import { OpportunityLocatorViewContext } from "../../context/OpportunityLocatorViewContext";
import { OpportunityLocatorViews } from "../../../../consts";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../context/OpportunitySearchContext";

export interface IOpportunitySearchFormProps {}

export function OpportunitySearchForm(
   props: IOpportunitySearchFormProps,
): ReactElement<IOpportunitySearchFormProps> {
   const { selectedView } = React.useContext(OpportunityLocatorViewContext);
   const { filtered } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   return (
      <Row className={"opportunity-search-form"}>
         <Col xs={12}>
            {filtered?.length === 0 ||
            selectedView === OpportunityLocatorViews.LANDING_LAYOUT ? (
               <OpportunitySearchBarLandingView />
            ) : (
               <OpportunitySearchBarFullScreenView />
            )}
         </Col>
      </Row>
   );
}
