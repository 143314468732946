import React, { useState } from "react";
import { OpportunityLocatorViews } from "app/consts";

export interface OpportunityLocatorViewContextType {
   selectedView: OpportunityLocatorViews;
   isScrollLoading: boolean;
   changeLayout: (arg: OpportunityLocatorViews) => void;
   renderBasicLayout: () => void;
   startScrollLoading: () => void;
   stopScrollLoading: (arg?: OpportunityLocatorViews) => void;
}

interface OpportunityLocatorViewContextProps {
   children: React.ReactNode;
}

const initialState: OpportunityLocatorViewContextType = {
   selectedView: OpportunityLocatorViews.LANDING_LAYOUT,
   isScrollLoading: false,
   changeLayout: (arg: OpportunityLocatorViews) => {},
   renderBasicLayout: () => {},
   startScrollLoading: () => {},
   stopScrollLoading: (arg?: OpportunityLocatorViews) => {},
};

export const OpportunityLocatorViewContext =
   React.createContext<OpportunityLocatorViewContextType>(initialState);

export const OpportunityLocatorViewContextProvider: React.FC<
   OpportunityLocatorViewContextProps
> = ({ children }) => {
   const [state, setState] =
      useState<OpportunityLocatorViewContextType>(initialState);

   const changeLayout = (selectedLayout: OpportunityLocatorViews): void => {
      setState({
         ...state,
         selectedView: selectedLayout,
      });
   };

   const renderBasicLayout = (): void => {
      setState({
         ...state,
         selectedView: OpportunityLocatorViews.LANDING_LAYOUT,
      });
   };

   const startScrollLoading = (): void => {
      setState({ ...state, isScrollLoading: true });
   };

   const stopScrollLoading = (
      renderLayout?: OpportunityLocatorViews | undefined,
   ): void => {
      setState({
         ...state,
         isScrollLoading: false,
         selectedView: renderLayout || state.selectedView,
      });
   };

   return (
      <OpportunityLocatorViewContext.Provider
         value={{
            ...state,
            changeLayout,
            renderBasicLayout,
            startScrollLoading,
            stopScrollLoading,
         }}
      >
         {children}
      </OpportunityLocatorViewContext.Provider>
   );
};
