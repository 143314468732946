import React, { useContext, useState } from "react";
import { Nav, Stack } from "react-bootstrap";
import { useNavigate } from "react-router";
import {
   ArrowIcon,
   CalendarImg,
   CircleUserSmallImg,
   CircleUserSolidImg,
   ClipBoardImg,
   DonationImg,
   GearImg,
   LogOutImg,
   PencilImg,
   PersonVcardImg,
   QuestionnaireButton,
   SearchImg,
   StyledNav,
   StyledNavDiv,
} from "./styled";
import { AuthContext, IAuthContext } from "../../../contexts/auth/authContext";
import { NavigateFunction, useLocation } from "react-router-dom";
import { LayoutContext } from "../../../layouts/context/layoutContext";
import { ROUTE_PATHS } from "../../../consts/RoutePaths";
import { EnvironmentContext } from "../../../contexts/environment/environmentContext";
import { IUserContext, UserContext } from "../../../contexts/user/userContext";

interface NavBarInterface {
   title: string;
   svg: JSX.Element;
   href: string;
}
const LayoutNavigationBarComponent = (): JSX.Element => {
   const { isNavBarExpanded } = useContext(LayoutContext);
   const navigate: NavigateFunction = useNavigate();
   const { pathname } = useLocation();
   const { signOut } = useContext<IAuthContext>(AuthContext);
   const { profileData } = useContext<IUserContext>(UserContext);
   const [displayProfileSection, setDisplayProfileSection] =
      useState<boolean>(true);
   const { environmentConfig } = useContext(EnvironmentContext);

   const NavBarUpperContent: NavBarInterface[] = [
      {
         title: "Profile",
         svg: <PersonVcardImg />,
         href: ROUTE_PATHS.PROFILE,
      },
      {
         title: "Preferences",
         svg: <GearImg />,
         href: ROUTE_PATHS.PREFERENCES,
      },
      {
         title: "Logout",
         svg: <LogOutImg />,
         href: "logout",
      },
   ];
   const NavBarLowerContent: NavBarInterface[] = [
      {
         title: "Opportunity Locator",
         svg: <SearchImg />,
         href: ROUTE_PATHS.LANDING_PATH,
      },
      {
         title: "Donations",
         svg: <DonationImg />,
         href: ROUTE_PATHS.MY_Donations,
      },
      {
         title: "Appointments",
         svg: <CalendarImg />,
         href: ROUTE_PATHS.MY_APPOINTMENTS,
      },
      {
         title: "Physical Exams",
         svg: <ClipBoardImg />,
         href: ROUTE_PATHS.PHYSICAL_EXAMS,
      },
   ];

   const navigationClick = (href: string): void => {
      if (href === "logout") {
         signOut();
      } else {
         navigate(href);
      }
   };

   return (
      <StyledNavDiv id={"NavBarComponent"}>
         <Stack
            gap={2}
            className="profile-name-section"
            onClick={() => setDisplayProfileSection(!displayProfileSection)}
         >
            {isNavBarExpanded ? <CircleUserSolidImg /> : <CircleUserSmallImg />}
            {isNavBarExpanded && (
               <span>
                  {profileData?.firstName} {profileData?.lastName}
                  <ArrowIcon
                     className={
                        displayProfileSection ? "up-arrow" : "down-arrow"
                     }
                  />
               </span>
            )}
         </Stack>
         <StyledNav
            className="d-md-block"
            onSelect={selectedKey => alert(`selected ${selectedKey}`)}
         >
            {displayProfileSection && (
               <div className="navbar-upper-section">
                  {NavBarUpperContent.map(eachLink => {
                     return (
                        <Nav.Item key={eachLink.title}>
                           <Nav.Link
                              className={`nav-each-item ${
                                 pathname === eachLink.href
                                    ? "selected-link"
                                    : ""
                              }`}
                              onClick={() => navigationClick(eachLink.href)}
                           >
                              {eachLink.svg}
                              {isNavBarExpanded && eachLink.title}
                           </Nav.Link>
                        </Nav.Item>
                     );
                  })}
               </div>
            )}
            <div className="navbar-lower-section">
               {NavBarLowerContent.map(eachLink => {
                  return (
                     <Nav.Item key={eachLink.title}>
                        <Nav.Link
                           className={`nav-each-item ${
                              pathname === eachLink.href ? "selected-link" : ""
                           }`}
                           onClick={() => navigationClick(eachLink.href)}
                        >
                           {eachLink.svg}
                           {isNavBarExpanded && eachLink.title}
                        </Nav.Link>
                     </Nav.Item>
                  );
               })}
            </div>
            {isNavBarExpanded &&
               environmentConfig &&
               environmentConfig.idhqURL && (
                  <QuestionnaireButton
                     className="btn-css"
                     onClick={() =>
                        window.open(environmentConfig.idhqURL, "_blank")
                     }
                  >
                     <PencilImg />{" "}
                     <span>
                        Pre-Donation <br /> Questionnaire
                     </span>
                  </QuestionnaireButton>
               )}
         </StyledNav>
      </StyledNavDiv>
   );
};
export default LayoutNavigationBarComponent;
