import React, { useEffect, useState } from "react";
import {
   AppointmentTimeDiv,
   AppointmentTimeLabel,
   AppointmentTimeSectionDiv,
   DownArrowIcon,
} from "./styled";
import { Col, Row } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";

interface IAppointmentTimeInterface {
   appointmentTime: string;
   available: boolean;
   appointmentSlotId: number;
}

const getHoursAndMinutes = (date: string | number | Date): string => {
   if (isNaN(Date.parse(date as string))) {
      return date as string;
   }
   const dateValue: Date = new Date(date);
   return dateValue
      .toLocaleTimeString("en-US", { hour: "numeric", minute: "2-digit" })
      .replace(" AM", "")
      .replace(" PM", "");
};

const getClassNames = (
   timeSelected,
   eachSection,
   isNotEligible: boolean,
): string => {
   const isFutureDate: boolean =
      new Date(eachSection.appointmentTime).getTime() > new Date().getTime();
   const classNameApplied =
      isFutureDate && eachSection.available && !isNotEligible
         ? "enable-time"
         : "disable-time";
   return timeSelected === eachSection.appointmentTime
      ? `selected ${classNameApplied}`
      : classNameApplied;
};

// The section displaying the available appointment times
const AppointmentTimeSection = ({
   sectionLabel,
   times,
   setTimeSelected,
   classNameSelected,
   timeSelected,
   isNotEligible,
   setTimeSlotSelectedId,
}) => {
   const timeSelectionFunction = (
      eachSection: IAppointmentTimeInterface,
   ): void => {
      setTimeSelected(eachSection.appointmentTime);
      setTimeSlotSelectedId(eachSection.appointmentSlotId);
   };
   return (
      <>
         {sectionLabel && (
            <AppointmentTimeLabel className={`mb-2 ${classNameSelected}`}>
               <b>{sectionLabel}</b>
            </AppointmentTimeLabel>
         )}
         <AppointmentTimeSectionDiv className={classNameSelected}>
            {times.map(eachSection => (
               <AppointmentTimeDiv
                  key={eachSection.appointmentTime}
                  onClick={() => timeSelectionFunction(eachSection)}
                  className={getClassNames(
                     timeSelected,
                     eachSection,
                     isNotEligible,
                  )}
               >
                  {getHoursAndMinutes(eachSection.appointmentTime)}
               </AppointmentTimeDiv>
            ))}
         </AppointmentTimeSectionDiv>
      </>
   );
};

function AppointmentTimes({
   appointmentTimesDisplayed,
   timeSelected,
   setTimeSelected,
   isNotEligible,
   setTimeSlotSelectedId,
   card,
}) {
   const [showMoreTimings, isShowMoreTimings] = useState<boolean>(false);
   const isLargeScreen: boolean = useMediaQuery({ minWidth: 992 });

   const [morningTimes, setMorningTimes] = useState<
      IAppointmentTimeInterface[]
   >([]);
   const [afternoonTimes, setAfternoonTimes] = useState<
      IAppointmentTimeInterface[]
   >([]);
   const [preEveningTimes, setPreEveningTimes] = useState<
      IAppointmentTimeInterface[]
   >([]);
   const [eveningTimes, setEveningTimes] = useState<
      IAppointmentTimeInterface[]
   >([]);
   const [postEveningTimes, setPostEveningTimes] = useState<
      IAppointmentTimeInterface[]
   >([]);

   const createAppointmentTimes = (): void => {
      const convertedTimes: IAppointmentTimeInterface[] = [];

      for (let eachTime of appointmentTimesDisplayed) {
         const timePushedAvailability: boolean | undefined =
            convertedTimes.find(
               eachVal => eachVal.appointmentTime === eachTime.appointmentTime,
            )?.available;

         const timePushedIndex: number = convertedTimes.findIndex(
            eachVal => eachVal.appointmentTime === eachTime.appointmentTime,
         );
         if (
            !timePushedAvailability &&
            eachTime.available &&
            timePushedIndex > -1
         ) {
            convertedTimes[timePushedIndex] = eachTime;
         }
         const isTimePushed: boolean = Boolean(
            convertedTimes.find(
               eachVal => eachVal.appointmentTime === eachTime.appointmentTime,
            ),
         );
         if (!isTimePushed) {
            convertedTimes.push(eachTime);
         }
      }

      const convertedMorningTimes: IAppointmentTimeInterface[] = convertedTimes
         .filter(each => new Date(each.appointmentTime).getHours() < 12)
         .sort(
            (a, b) =>
               new Date(a.appointmentTime).getTime() -
               new Date(b.appointmentTime).getTime(),
         );
      const convertedRemainingTimes: IAppointmentTimeInterface[] =
         convertedTimes
            .filter(each => new Date(each.appointmentTime).getHours() >= 12)
            .sort(
               (a, b) =>
                  new Date(a.appointmentTime).getTime() -
                  new Date(b.appointmentTime).getTime(),
            );

      const afternoonTimesData: IAppointmentTimeInterface[] = [];
      const preEveningTimesData: IAppointmentTimeInterface[] = [];
      const eveningTimesData: IAppointmentTimeInterface[] = [];
      const postEveningTimes: IAppointmentTimeInterface[] = [];

      //@ Todo fix refactor this logic from if else to switch after release

      for (let eachTime of convertedRemainingTimes) {
         if (afternoonTimesData.length < 8) {
            afternoonTimesData.push(eachTime);
         } else {
            if (preEveningTimesData.length < 8) {
               preEveningTimesData.push(eachTime);
            } else {
               if (eveningTimesData.length < 8) {
                  eveningTimesData.push(eachTime);
               } else {
                  if (postEveningTimes.length < 8) {
                     postEveningTimes.push(eachTime);
                  }
               }
            }
         }
      }
      setMorningTimes(convertedMorningTimes);
      setAfternoonTimes(afternoonTimesData);
      setPreEveningTimes(preEveningTimesData);
      setEveningTimes(eveningTimesData);
      setPostEveningTimes(postEveningTimes);
   };

   useEffect(() => {
      if (appointmentTimesDisplayed.length) {
         createAppointmentTimes();
      }
   }, [appointmentTimesDisplayed]);

   const remainingTimesCount = (
      remainingTimes: IAppointmentTimeInterface[],
   ): number => {
      return remainingTimes.filter(eachTime => eachTime.available).length;
   };

   const remainingTimes: number = remainingTimesCount([
      ...preEveningTimes,
      ...eveningTimes,
      ...postEveningTimes,
   ]);

   return (
      <div className="mb-5">
         <Row className="mb-4">
            <Col lg="6" className="mb-4">
               <AppointmentTimeSection
                  sectionLabel="Morning (AM)"
                  times={morningTimes}
                  setTimeSelected={setTimeSelected}
                  classNameSelected={"margin-right-selected"}
                  timeSelected={timeSelected}
                  isNotEligible={isNotEligible}
                  setTimeSlotSelectedId={setTimeSlotSelectedId}
               />
            </Col>
            <Col lg="6">
               <AppointmentTimeSection
                  sectionLabel="Afternoon (PM)"
                  times={afternoonTimes}
                  setTimeSelected={setTimeSelected}
                  classNameSelected={
                     isLargeScreen
                        ? "margin-left-selected"
                        : "margin-right-selected"
                  }
                  timeSelected={timeSelected}
                  isNotEligible={isNotEligible}
                  setTimeSlotSelectedId={setTimeSlotSelectedId}
               />
            </Col>
         </Row>
         {remainingTimes > 0 && (
            <div className="mb-4">
               Show more times{" "}
               <DownArrowIcon
                  onClick={() => isShowMoreTimings(!showMoreTimings)}
               />{" "}
               ({remainingTimes} available)
            </div>
         )}
         {showMoreTimings && (
            <>
               <Row className="mb-4">
                  <Col lg="6" className="mb-4">
                     <AppointmentTimeSection
                        sectionLabel=""
                        times={preEveningTimes}
                        setTimeSelected={setTimeSelected}
                        classNameSelected={"margin-right-selected"}
                        timeSelected={timeSelected}
                        isNotEligible={isNotEligible}
                        setTimeSlotSelectedId={setTimeSlotSelectedId}
                     />
                  </Col>
                  <Col lg="6">
                     <AppointmentTimeSection
                        sectionLabel=""
                        times={eveningTimes}
                        setTimeSelected={setTimeSelected}
                        classNameSelected={
                           isLargeScreen
                              ? "margin-left-selected"
                              : "margin-right-selected"
                        }
                        timeSelected={timeSelected}
                        isNotEligible={isNotEligible}
                        setTimeSlotSelectedId={setTimeSlotSelectedId}
                     />
                  </Col>
               </Row>
               <Row>
                  <Col lg="6">
                     <AppointmentTimeSection
                        sectionLabel=""
                        times={postEveningTimes}
                        setTimeSelected={setTimeSelected}
                        classNameSelected={"margin-right-selected"}
                        timeSelected={timeSelected}
                        isNotEligible={isNotEligible}
                        setTimeSlotSelectedId={setTimeSlotSelectedId}
                     />
                  </Col>
               </Row>
            </>
         )}
      </div>
   );
}

export default AppointmentTimes;
