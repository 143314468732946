import React, { ReactElement } from "react";

export interface IAppointmentTextProps {
   isSingleAppointmentType: boolean;
}

export function AppointmentText({
   isSingleAppointmentType,
}: IAppointmentTextProps): ReactElement {
   return (
      <b className="appointment-text">
         {!isSingleAppointmentType
            ? `Now select an Appointment Type and then find a time that works for you!`
            : `Now let's find a time that works for you!`}
      </b>
   );
}
