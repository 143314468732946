import styled from "styled-components";
import { ReactComponent as ArrowForward } from "../../../../../assets/svgs/forward-arrow.svg";
import { PRIMARY_COLORS } from "../../../../consts/colors";

export const HorizontalCalendarOuterDiv = styled.div`
   border-bottom: 2px solid lightgray;
   padding-bottom: 20px;
`;

export const HorizontalCalendarDiv = styled.div`
   background-color: ${PRIMARY_COLORS.ALABASTER};
   &.mobile-version {
      width: 100%;
   }
   &.desktop-version {
      width: 80%;
      margin-left: 10%;
   }
   .month-selection-div {
      display: flex;
      justify-content: center;
      margin-bottom: 5px;
      .month-select {
         background-color: ${PRIMARY_COLORS.ALABASTER};
         border: none !important;
         font-weight: 600;
         z-index: 1;
      }
   }
   .date-outer-section {
      .date-section-group {
         width: 50px;
         height: 50px;
         align-items: center;
         justify-content: center;
         display: flex;

         .each-date-section {
            display: flex;
            flex-direction: column;
            .date-title {
               font-size: 14px;
               font-weight: 600;
               text-align: center;
            }
            .date-text {
               text-align: center;
               font-size: 11px;
            }
         }

         &.selected-date {
            background-color: ${PRIMARY_COLORS.LAVA_RED};
            border-radius: 50%;
            border: 1px solid black;
            .each-date-section {
               color: white;
            }
         }
         &.unselected-date {
            .each-date-section {
               .date-title {
                  color: ${PRIMARY_COLORS.MYRTLE};
               }
               .data-text {
                  color: ${PRIMARY_COLORS.CARBON_GREY};
               }
            }
         }
      }
   }

   .react-horizontal-scrolling-menu--scroll-container::-webkit-scrollbar {
      display: none;
   }

   .react-horizontal-scrolling-menu--scroll-container {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
   }
   .left-arrow-btn {
      padding-left: 0px;
   }
   .right-arrow-btn {
      padding-right: 0px;
   }
`;

export const ArrowLeft = styled(ArrowForward)`
   transform: rotate(180deg);
   fill: ${PRIMARY_COLORS.DARK_GREEN_BLUE};
`;

export const ArrowRight = styled(ArrowForward)`
   fill: ${PRIMARY_COLORS.DARK_GREEN_BLUE};
`;
