import React, { useContext, useEffect, useState } from "react";
import { ReactComponent as ClockIcon } from "assets/svgs/clock-fill.svg";
import CancelAppointmentIcon from "assets/images/cancel-appointment.png";
import RescheduleAppointmentIcon from "assets/images/reschedule-appointment.png";
import { IAppointment } from "types/IAppointment";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import {
   LocationOrAccountOptions,
   ModalPageNames,
} from "../../../../../consts";
import { PRIMARY_COLORS } from "../../../../../consts/colors";
import SignInModal from "../../../../../components/AuthenticationModals/SignInModal";
import { IConfirmedAppointmentDetails } from "../../../../../../types/IConfirmedApponitmentDetails";
import { AppointmentSlot } from "../../../../../components/AppointmentSlot/AppointmentSlot";
import { getIconForLocationType } from "../../../../../components/LocationTypeImageIcon/getIconForLocationType";
import { AppointmentKinds } from "../../../../../consts/AppointmentKinds";
import { UserContext } from "../../../../../contexts/user/userContext";
import { CommonContext } from "../../../../../contexts/common/commonContext";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../../../components/OpportunitySearchComponent/context/OpportunitySearchContext";

interface FutureAppointmentSlotProps {
   appointment: IAppointment;
   setTriggerAppointmentPageCall: (_: boolean) => void;
}

const FutureAppointmentDesktopSlot: React.FC<FutureAppointmentSlotProps> = ({
   appointment,
   setTriggerAppointmentPageCall,
}) => {
   const Month: string = format(
      new Date(appointment.appointmentDateTime),
      "MMM",
   );
   const { profileData } = useContext(UserContext);
   const { currentOriginZipCodeFromBrowser } = useContext(CommonContext);
   const { filters } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );
   const Day: string = format(new Date(appointment.appointmentDateTime), "dd");
   const [modalShow, setModalShow] = React.useState<boolean>(false);
   const [appointmentKind, setAppointmentKind] = useState<string>("");
   const [isCancelConfirmed, setIsCancelConfirmed] = useState<boolean>(false);
   const confirmedAptDetails: IConfirmedAppointmentDetails = {
      addressOne: appointment?.drive?.location?.addressOne,
      name: appointment?.drive?.location?.name,
      timeSelected: appointment.appointmentDateTime,
      dateSelected: appointment.appointmentDateTime,
      city: appointment?.drive?.location?.city,
      state: appointment?.drive?.location?.state,
      zipCode: appointment?.drive?.location?.zipCode,
      appointmentKind,
      appointmentId: appointment.appointmentId,
      donorId: appointment.donorId,
      driveId: appointment?.drive?.driveId,
      driveDate: appointment?.drive?.driveDate,
   };

   const rescheduleOrCancelBtnClicked = (aptKind: string): void => {
      setAppointmentKind(aptKind);
      setModalShow(true);
   };

   useEffect(() => {
      if (isCancelConfirmed) {
         setTriggerAppointmentPageCall(true);
         setIsCancelConfirmed(false);
      }
   }, [isCancelConfirmed]);

   return (
      <>
         <AppointmentSlot
            month={Month}
            day={Day}
            iconSrc={getIconForLocationType(
               appointment?.drive?.location?.locationType,
            )}
            name={appointment?.drive?.location?.name}
            subTitle={getStrictFormattedAddress(appointment?.drive?.location)}
            rightTopValue={format(
               new Date(appointment.appointmentDateTime),
               "p",
            )}
            rightTopIcon={
               <ClockIcon className="mr-3" fill={PRIMARY_COLORS.STAR_DUST} />
            }
            rightBottomLabel={"Appt Type"}
            rightBottomValue={appointment.appointmentTypeDescription}
            mapDirections={{
               from:
                  (filters?.searchType === LocationOrAccountOptions.LOCATION &&
                     `${filters.searchOrigin}`) ||
                  (currentOriginZipCodeFromBrowser &&
                     `${currentOriginZipCodeFromBrowser}`) ||
                  profileData?.zipCode ||
                  profileData?.state ||
                  appointment.drive.location.zipCode,
               to: `${appointment.drive.location.addressOne} ${appointment.drive.location.addressTwo}, ${appointment.drive.location.zipCode}`,
            }}
            actionButtons={[
               {
                  onClick: () =>
                     rescheduleOrCancelBtnClicked(AppointmentKinds.CANCEL),
                  text: "Cancel",
                  imageSrc: CancelAppointmentIcon,
               },
               {
                  onClick: () =>
                     rescheduleOrCancelBtnClicked(AppointmentKinds.RESCHEDULED),
                  text: "Reschedule",
                  imageSrc: RescheduleAppointmentIcon,
               },
            ]}
         />
         <SignInModal
            show={modalShow}
            setModalShow={setModalShow}
            pageNameSent={ModalPageNames.APPOINTMENT_CONFIRMATION}
            confirmedAptDetails={confirmedAptDetails}
            setIsCancelConfirmed={setIsCancelConfirmed}
         />
      </>
   );
};

export default FutureAppointmentDesktopSlot;
