import styled from "styled-components";
import { PRIMARY_COLORS } from "../../../../../consts/colors";

export const StyledSlot = styled.div`
   width: 100%;
   background-color: ${PRIMARY_COLORS.WHITE};
   margin-bottom: 10px;

   &:hover {
      .date {
         background-color: ${PRIMARY_COLORS.LINK_WATER} !important;
      }
      .wrapper,
      .actions {
         background-color: ${PRIMARY_COLORS.LILY_WHITE};
      }
   }

   .wrapper {
      width: 100%;
      height: 140px;
      display: flex;

      .date {
         width: 90px;
         height: 100%;
         background-color: ${PRIMARY_COLORS.AQUA_HAZE};
         border-radius: 3px;
         padding: 4px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         display: flex;
         flex-direction: column;
         gap: 10px;
         justify-content: center;
         align-items: center;

         p {
            font-size: 25px !important;
            font-weight: bold;
         }
      }

      .details-section {
         width: calc(100% - 90px);
         border-radius: 5px;
         border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
         padding: 10px 20px;

         @media screen and (max-width: 1000px) {
            border-top: 4px solid green;
         }

         .title {
            display: flex;
            align-items: center;
            gap: 8px;

            img {
               width: 20px;
               height: auto;
            }

            span {
               font-size: 14px !important;
               font-weight: bold;
            }
         }

         .sub-title {
            font-size: 12px !important;
            margin-top: 4px;
            line-height: 24px;
         }

         .bottom-section {
            display: flex;
            justify-content: space-between;
            align-items: center;
            img {
               width: 35px;
               height: auto;
               cursor: pointer;
               align-self: flex-end;
            }
         }
      }
   }
`;

export const StyledActions = styled.div<any>`
   width: 100% !important;
   border-radius: 3px;
   width: 340px;
   /* padding: 20px; */
   border: 1px solid ${PRIMARY_COLORS.LIGHT_GREY};
   display: flex;
   align-items: center;
   justify-content: space-around;
   gap: 20px;
   padding: ${props => (props.isMobile ? "10px" : "20px")};
   &:hover,
   &:hover {
      background-color: ${PRIMARY_COLORS.LILY_WHITE};
   }

   button {
      background-color: white;
      border-radius: 6px;
      padding: 7px 12px;
      border: 2px solid lightgrey;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;

      img {
         width: 40px;
         height: auto;
      }
   }
`;
