import styled from "styled-components";
import { ReactComponent as CheckMarkIcon } from "../../../../../../assets/svgs/check2-circle.svg";

export const CheckMarkImage = styled(CheckMarkIcon)`
   background: none;
   top: 1.1em;
   left: 5%;
   position: absolute;
   z-index: 5;
`;
