import React, { useContext, useState } from "react";
import Form from "react-bootstrap/Form";
import { Email, Password } from "../authComponents";
import Button from "react-bootstrap/Button";
import { Modal, Row } from "react-bootstrap";
import {
   useValidEmail,
   useValidPassword,
} from "../../../hooks/useAuthValidationHook";
import { AuthContext } from "../../../contexts/auth/authContext";
import { ModalPageNames } from "../../../consts";
import { ReactComponent as BoxArrowInRight } from "../../../../assets/svgs/box-arrow-in-right.svg";
import { CommonModalHeader } from "../styled";
import { SignInContainer, SignInModalAlert } from "./styled";
import { useMediaQuery } from "react-responsive";

const SignInComponent = ({ setPageName, onClose, setEmailToBeVerified }) => {
   const { email, setEmail } = useValidEmail("");
   const { password, setPassword } = useValidPassword("");
   const [passwordVisible, isPasswordVisible] = useState(false);
   const [error, setError] = useState("");

   const isInValid: boolean = email.length === 0 || password.length === 0;

   const authContext = useContext(AuthContext);

   const isMobile = useMediaQuery({ maxWidth: 767 });

   const signInClicked = async () => {
      try {
         await authContext.signInWithEmail(email, password);
         onClose();
      } catch (err: any) {
         if (err.error?.code === "UserNotConfirmedException") {
            await resendCodeConfirmation();
         } else {
            setError(err.error?.message || err?.message);
         }
      }
   };

   const resendCodeConfirmation = async () => {
      try {
         await authContext.resendCode(email);
         setEmailToBeVerified(email);
         setPageName(ModalPageNames.VERIFYING_ACCOUNT);
      } catch (err: any) {
         console.error(err.message);
      }
   };

   const passwordResetClicked = async () => {
      setPageName(ModalPageNames.FORGET_PASSWORD);
   };

   return (
      <>
         <Modal.Header closeButton></Modal.Header>
         <CommonModalHeader
            className={isMobile ? "mobile-header-css" : "desktop-header-css"}
         >
            <h5>
               <b>Sign In</b>
            </h5>
         </CommonModalHeader>
         {error && (
            <SignInModalAlert
               variant="danger"
               className={isMobile ? "mobile-alert-css" : "desktop-alert-css"}
            >
               {error}
            </SignInModalAlert>
         )}
         <Modal.Body>
            <SignInContainer
               className={isMobile ? "mobile-css" : "desktop-css"}
            >
               <Form.Group className={isMobile ? "" : "mb-5"}>
                  <Email
                     emailIsValid
                     setEmail={setEmail}
                     placeholderValue="Email"
                     isRequired={false}
                  />
                  <Password
                     label="Password"
                     passwordIsValid
                     setPassword={setPassword}
                     setError={setError}
                     passwordVisible={passwordVisible}
                     isPasswordVisible={isPasswordVisible}
                     isRequired={false}
                     isPasswordShowRequired
                  />
                  <Form.Group className="forgot-password-group">
                     <Button
                        className="forgot-password-link"
                        variant="link"
                        onClick={passwordResetClicked}
                     >
                        Forgot password?
                     </Button>
                  </Form.Group>
                  <Form.Group
                     as={Row}
                     className={`${
                        isMobile ? "mb-3" : "mb-5"
                     } remember-label-group`}
                     controlId="formKeepMeSignedInCheck"
                  >
                     <Form.Check
                        label="Remember me"
                        className="remember-label"
                     />
                  </Form.Group>
                  <Form.Group>
                     <Button
                        disabled={isInValid}
                        className="sign-in-button"
                        onClick={signInClicked}
                        variant="danger"
                     >
                        Sign In <BoxArrowInRight className="box-arrow-css" />
                     </Button>
                  </Form.Group>
               </Form.Group>
               <Form.Group className="mb-3 create-account-group">
                  <span>Don't have an account?</span>
                  <Button
                     className="create-account-link"
                     variant="link"
                     onClick={() => setPageName(ModalPageNames.NEW_ACCOUNT)}
                  >
                     <b>Create an account</b>
                  </Button>
               </Form.Group>
            </SignInContainer>
         </Modal.Body>
      </>
   );
};

export default SignInComponent;
