import React, { useEffect, useState } from "react";
import { StyledActions, StyledSlot } from "./styled";
import { ReactComponent as ClockIcon } from "assets/svgs/clock-fill.svg";
import MapIcon from "assets/images/map-icon.png";
import CancelAppointmentIcon from "assets/images/cancel-appointment.png";
import RescheduleAppointmentIcon from "assets/images/reschedule-appointment.png";
import { IAppointment } from "types/IAppointment";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import { Image } from "react-bootstrap";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { ModalPageNames } from "../../../../../consts";
import { PRIMARY_COLORS } from "../../../../../consts/colors";
import SignInModal from "../../../../../components/AuthenticationModals/SignInModal";
import { IConfirmedAppointmentDetails } from "../../../../../../types/IConfirmedApponitmentDetails";
import { LocationTypeImageIcon } from "../../../../../components/LocationTypeImageIcon/LocationImageIcon";
import { AppointmentKinds } from "../../../../../consts/AppointmentKinds";
import { ROUTE_PATHS } from "../../../../../consts/RoutePaths";

interface FutureAppointmentMobileSlotProps {
   appointment: IAppointment;
   setTriggerAppointmentPageCall: (_: boolean) => void;
}

const FutureAppointmentMobileSlot: React.FC<
   FutureAppointmentMobileSlotProps
> = ({ appointment, setTriggerAppointmentPageCall }) => {
   const navigate: NavigateFunction = useNavigate();
   const isMobile: boolean = useMediaQuery({ maxWidth: 1000 });
   const Month: string = format(
      new Date(appointment.appointmentDateTime),
      "MMM",
   );
   const Day: string = format(new Date(appointment.appointmentDateTime), "dd");
   const [appointmentKind, setAppointmentKind] = useState<string>("");
   const [modalShow, setModalShow] = React.useState<boolean>(false);
   const [isCancelConfirmed, setIsCancelConfirmed] = useState<boolean>(false);
   const confirmedAptDetails: IConfirmedAppointmentDetails = {
      addressOne: appointment?.drive?.location?.addressOne,
      name: appointment?.drive?.location?.name,
      timeSelected: appointment.appointmentDateTime,
      dateSelected: appointment.appointmentDateTime,
      city: appointment?.drive?.location?.city,
      state: appointment?.drive?.location?.state,
      zipCode: appointment?.drive?.location?.zipCode,
      appointmentKind,
      appointmentId: appointment.appointmentId,
      donorId: appointment.donorId,
      driveId: appointment?.drive?.driveId,
      driveDate: appointment?.drive?.driveDate,
   };
   const rescheduleOrCancelBtnClicked = (aptKind: string): void => {
      setAppointmentKind(aptKind);
      setModalShow(true);
   };
   useEffect(() => {
      if (isCancelConfirmed) {
         setTriggerAppointmentPageCall(true);
         setIsCancelConfirmed(false);
      }
   }, [isCancelConfirmed]);
   return (
      <>
         <StyledSlot className={"futureMobileSlot"}>
            <div className="wrapper">
               <div className="date">
                  <p>{Month}</p>
                  <p>{Day}</p>
               </div>

               <div className="details-section">
                  <p className="title text-truncate w-100">
                     <LocationTypeImageIcon
                        locationType={appointment.drive.location.locationType}
                     />
                     <span> {appointment?.drive?.location?.name}</span>
                  </p>
                  <p className="sub-title text-truncate w-100">
                     {getStrictFormattedAddress(appointment?.drive?.location)}
                  </p>

                  <div className="bottom-section">
                     <div>
                        <p className="sub-title mt-2">
                           Appt Type:{" "}
                           <b>{appointment.appointmentTypeDescription}</b>
                        </p>
                        <p className="title">
                           <ClockIcon
                              className="mr-3"
                              fill={PRIMARY_COLORS.STAR_DUST}
                           />
                           <span>
                              {format(
                                 new Date(appointment.appointmentDateTime),
                                 "p",
                              )}
                           </span>
                        </p>
                     </div>

                     <Image
                        src={MapIcon}
                        onClick={() => {
                           navigate(ROUTE_PATHS.FUTURE_APPOINTMENTS_MAP, {
                              state: appointment,
                           });
                        }}
                     />
                  </div>
               </div>
            </div>

            <StyledActions className="actions" isMobile={isMobile}>
               <button
                  onClick={() =>
                     rescheduleOrCancelBtnClicked(AppointmentKinds.CANCEL)
                  }
               >
                  <Image src={CancelAppointmentIcon} />
                  Cancel
               </button>
               <button
                  onClick={() =>
                     rescheduleOrCancelBtnClicked(AppointmentKinds.RESCHEDULED)
                  }
               >
                  <Image src={RescheduleAppointmentIcon} />
                  Reschedule
               </button>
            </StyledActions>
         </StyledSlot>
         <SignInModal
            show={modalShow}
            setModalShow={setModalShow}
            pageNameSent={ModalPageNames.APPOINTMENT_CONFIRMATION}
            confirmedAptDetails={confirmedAptDetails}
            setIsCancelConfirmed={setIsCancelConfirmed}
         />
      </>
   );
};

export default FutureAppointmentMobileSlot;
