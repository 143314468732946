import axios from "axios";

const baseUrl = "https://maps.googleapis.com/maps/api/geocode/json?";

export const getZipCodeBasedOnLatLng = async (
   latlng: string,
): Promise<string | undefined> => {
   try {
      const res = await axios(
         `${baseUrl}latlng=${latlng}&sensor=true&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`,
      );
      let postalCode = "";
      if (res.data.status === "OK") {
         const addressComponents = res.data.results[0].address_components;
         for (let i = 0; i < addressComponents.length; i++) {
            if (addressComponents[i].types[0] === "postal_code") {
               postalCode = addressComponents[i].long_name;
               break;
            }
         }
      }
      return postalCode;
   } catch (err) {
      console.error(err);
      return undefined;
   }
};
