import { Dayjs, default as dayjs } from "dayjs";
import React from "react";
import { useEffect, useState } from "react";
import {
   getItemsPos,
   ScrollMenu,
   slidingWindow,
   VisibilityContext,
} from "react-horizontal-scrolling-menu";
import {
   ArrowLeft,
   ArrowRight,
   HorizontalCalendarDiv,
   HorizontalCalendarOuterDiv,
} from "./styled";
import { Button } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import { useMediaQuery } from "react-responsive";

interface IDayList {
   id: string;
   value: Dayjs;
}

interface ICard {
   onClick: Function;
   title: string;
   itemId: string;
   text: string;
}

interface IMonth {
   label: string;
   value: number;
   id: string;
}

const HorizontalCalendar = ({ driveDate, setSelectedDate }) => {
   const [daysList, setDaysList] = useState<IDayList[]>([]);
   const [selected, setSelected] = useState<string>("");
   const [monthNeedsToBeSelected, setMonthNeedsToBeSelected] = useState<
      IMonth[]
   >([]);
   const [monthSelected, setMonthSelected] = useState<number | undefined>(
      undefined,
   );

   const isMobile: boolean = useMediaQuery({ maxWidth: 767 });

   const findingSpecificDateValue = (id: string): Dayjs | undefined => {
      return daysList.find(eachDay => eachDay.id === id)?.value;
   };

   const monthNames: string[] = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
   ];

   type scrollVisibilityApiType = React.ContextType<typeof VisibilityContext>;

   const apiRef = React.useRef({} as scrollVisibilityApiType);

   const monthDropdownChange = (month: number): void => {
      setMonthSelected(month);
      if (month !== undefined) {
         const currentMonthId: string =
            monthNeedsToBeSelected.find(val => val.value === month)?.id || "";
         apiRef.current.scrollToItem(
            apiRef.current.getItemById(currentMonthId),
            "auto",
            "start",
         );
      }
   };

   const getMonthValLabel = (monthValue: number, id: string): IMonth => {
      return {
         label: monthNames[monthValue],
         value: monthValue,
         id,
      };
   };

   const getMonthValue = (id: string | undefined): number | undefined => {
      return daysList.find(eachDate => id === eachDate.id)?.value.month();
   };

   const getNextNinetyDays = (): IDayList[] => {
      const days: IDayList[] = [];
      const months: IMonth[] = [];
      for (let i = 0; i <= 89; i++) {
         const dayNeedsToBePushed: Dayjs = dayjs().add(i, "day");
         const uniqueId: string = uuidv4().toString();
         days.push({ id: uniqueId, value: dayNeedsToBePushed });
         const monthVal: number = dayNeedsToBePushed.month();
         if (!months.find(val => val.value === monthVal)) {
            months.push(getMonthValLabel(monthVal, uniqueId));
         }
         if (driveDate === dayjs(dayNeedsToBePushed).format("YYYY-MM-DD")) {
            setSelected(uniqueId);
            setMonthSelected(monthVal);
            setSelectedDate(dayNeedsToBePushed);
         }
      }
      setMonthNeedsToBeSelected(months);
      return days;
   };

   const LeftArrow = (): JSX.Element => {
      const { isFirstItemVisible, scrollPrev, items, visibleItems } =
         React.useContext(VisibilityContext);
      const allItems: string[] = [];

      items.forEach(each => {
         if (!each.key.includes("seperator")) {
            allItems.push(each?.key);
         }
      });
      const prevGroup = slidingWindow(allItems, visibleItems).prev();
      const { first } = getItemsPos(prevGroup);
      return (
         <Button
            variant="link"
            className="left-arrow-btn"
            disabled={isFirstItemVisible}
            onClick={() => {
               scrollPrev();
               setMonthSelected(getMonthValue(first));
            }}
         >
            <ArrowLeft />
         </Button>
      );
   };

   const RightArrow = (): JSX.Element => {
      const { isLastItemVisible, scrollNext, getNextElement } =
         React.useContext(VisibilityContext);
      return (
         <Button
            variant="link"
            className="right-arrow-btn"
            disabled={isLastItemVisible}
            onClick={() => {
               scrollNext();
               setMonthSelected(getMonthValue(getNextElement()?.key));
            }}
         >
            <ArrowRight />
         </Button>
      );
   };

   useEffect(() => {
      setDaysList(getNextNinetyDays());
   }, []);

   const onLoadCalendar = (): void => {
      apiRef.current.scrollToItem(
         apiRef.current.getItemById(selected),
         "auto",
         "start",
      );
   };

   function Card({ onClick, title, itemId, text }: ICard) {
      const visibility = React.useContext(VisibilityContext);
      return (
         <div
            onClick={() => onClick(visibility)}
            tabIndex={0}
            className={`date-section-group ${
               itemId === selected ? "selected-date" : "unselected-date"
            }`}
         >
            <div className="each-date-section">
               <span className="date-title">{title}</span>
               <span className="date-text">{text}</span>
            </div>
         </div>
      );
   }
   const handleClick =
      (id: string): (() => void) =>
      (): void => {
         setSelected(id);
         setMonthSelected(getMonthValue(id));
         setSelectedDate(findingSpecificDateValue(id));
      };

   return (
      <HorizontalCalendarOuterDiv>
         <HorizontalCalendarDiv
            className={isMobile ? "mobile-version" : "desktop-version"}
         >
            <div className="month-selection-div">
               <select
                  className="month-select mb-1"
                  value={monthSelected}
                  onChange={e => monthDropdownChange(Number(e.target.value))}
               >
                  {monthNeedsToBeSelected.map(({ label, value }) => {
                     return (
                        <option key={value} value={value}>
                           {label}
                        </option>
                     );
                  })}
               </select>
            </div>
            <ScrollMenu
               apiRef={apiRef}
               LeftArrow={LeftArrow}
               RightArrow={RightArrow}
               itemClassName="date-outer-section"
               onInit={onLoadCalendar}
            >
               {daysList.map(({ id, value }) => (
                  <Card
                     itemId={id} // NOTE: itemId is required for track items
                     title={value.format("D")}
                     text={value.format("ddd")}
                     key={id}
                     onClick={handleClick(id)}
                  />
               ))}
            </ScrollMenu>
         </HorizontalCalendarDiv>
      </HorizontalCalendarOuterDiv>
   );
};

export default HorizontalCalendar;
