import styled from "styled-components";

export const BloodDriveMarkerPopupWrapper = styled.div`
   background-color: white;
   padding: 17px;
   position: relative;
   border: 2px solid #3a414a;
   border-radius: 8px;

   &::after {
      content: "";
      position: absolute;
      top: calc(100% - 19px); /*i.e. half the height*/
      left: 85px;
      height: 40px;
      width: 30px;
      background: white;
      transform: rotate(54deg);
      box-shadow: 0px 1px 2px 0px rgba(60, 64, 67, 0.3),
         0px 2px 6px 2px rgba(60, 64, 67, 0.15);
      border-bottom: inherit;
      border-right: inherit;
      box-shadow: inherit;
   }

   button {
      background: none !important;
      color: blue;
      font-size: 12px;
      border: none;
      outline: none !important;
      margin-top: 10px;
      :hover,
      :focus {
         color: darkblue;
      }
   }
`;

export const BloodDriveMarkerTitle = styled.h5`
   font-size: 12px !important;
   font-weight: bold;
`;

export const BloodDriveMarkerSubTitle = styled.p`
   font-size: 10px !important;
`;
