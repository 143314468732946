import React from "react";
import { StyledSlot } from "./styled";
import { IAppointment } from "types/IAppointment";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import { LocationTypeImageIcon } from "../../../../../../components/LocationTypeImageIcon/LocationImageIcon";

interface PastAppointmentMobileSlotProps {
   appointment: IAppointment;
}

function getAppointmentOutcomeColor(outcome: string): string {
   switch (outcome) {
      case "Canceled":
      case "No Show":
         return "red";
      default:
         return "green";
   }
}
const PastAppointmentMobileSlot: React.FC<PastAppointmentMobileSlotProps> = ({
   appointment,
}) => {
   const Month: string = format(
      new Date(appointment.appointmentDateTime),
      "MMM",
   );
   const Day: string = format(new Date(appointment.appointmentDateTime), "dd");
   return (
      <>
         <StyledSlot
            className={"pastMobileSlot"}
            statusColor={getAppointmentOutcomeColor(appointment.outcome)}
         >
            <div className="wrapper ">
               <div className="date">
                  <p>{Month}</p>
                  <p>{Day}</p>
               </div>

               <div className="details-section">
                  <p className="title text-truncate w-100">
                     <LocationTypeImageIcon
                        locationType={appointment.drive.location.locationType}
                     />
                     <span> {appointment?.drive?.location?.name}</span>
                  </p>

                  <p className="sub-title text-truncate w-100">
                     {getStrictFormattedAddress(appointment?.drive?.location)}
                  </p>

                  <p className="sub-title mt-2">
                     Appt Type: <b>{appointment?.appointmentTypeDescription}</b>
                  </p>
                  <p className="sub-title mt-0 ">
                     Status: {appointment.outcome}
                  </p>
               </div>
            </div>
         </StyledSlot>
      </>
   );
};

export default PastAppointmentMobileSlot;
