import React, { ForwardedRef } from "react";
import { Form, FloatingLabel, InputGroup, FormControl } from "react-bootstrap";
import DatePickerReact from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MaskedInput from "react-text-mask";
import {
   CalendarDateImg,
   CityBuildingsImg,
   DateOfBirthDiv,
   EmailImg,
   EyeImageFIll,
   EyeImageSlashFIll,
   GenderRadioSection,
   LockImg,
   PasswordSectionDiv,
   PasswordVisibleIconSpan,
   PersonImg,
   PersonVcardImg,
   PhoneImg,
   RequiredFieldSpanStyled,
   StreetAddressImg,
   StyledFormControl,
} from "./styled";
import { FormSelect as FormSelectOptions } from "./styled";
import { US_STATES } from "../../consts/usStates";

export const Email: React.FunctionComponent<{
   emailIsValid: boolean;
   setEmail: (_: string) => void;
   placeholderValue?: string;
   isRequired: boolean;
}> = ({ emailIsValid, setEmail, placeholderValue, isRequired }) => {
   return (
      <div className="mb-3 position-relative">
         {isRequired && <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>}
         <EmailImg />
         <StyledFormControl
            type="email"
            placeholder={emailIsValid ? `${placeholderValue}` : "Invalid Email"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setEmail(evt.target.value);
            }}
            isInvalid={!emailIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please select a valid email address.
         </Form.Control.Feedback>
      </div>
   );
};

export const Password: React.FunctionComponent<{
   label: string;
   passwordIsValid: boolean;
   setPassword: (_: string) => void;
   setError?: (_: string) => void;
   passwordVisible?: boolean;
   isPasswordVisible: (_: boolean) => void;
   isPasswordSame?: boolean;
   isRequired: boolean;
   isPasswordShowRequired?: boolean;
}> = ({
   label,
   passwordIsValid,
   setPassword,
   passwordVisible,
   isPasswordVisible,
   isPasswordSame,
   isRequired,
   isPasswordShowRequired,
}) => {
   return (
      <PasswordSectionDiv className="mb-3 position-relative">
         {isRequired && <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>}
         <LockImg />
         <StyledFormControl
            type={passwordVisible ? "text" : "password"}
            placeholder={passwordIsValid ? label : "Minimum 8 characters"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setPassword(evt.target.value);
            }}
            isInvalid={!passwordIsValid}
         />
         {isPasswordShowRequired && (
            <PasswordVisibleIconSpan
               onClick={() => isPasswordVisible(!passwordVisible)}
            >
               {passwordVisible ? <EyeImageSlashFIll /> : <EyeImageFIll />}
            </PasswordVisibleIconSpan>
         )}
         <Form.Control.Feedback type="invalid">
            {isPasswordSame === false
               ? "Passwords do not match"
               : "Password needs to have minimum 8 characters"}
         </Form.Control.Feedback>
      </PasswordSectionDiv>
   );
};

export const Username: React.FunctionComponent<{
   usernameIsValid: boolean;
   setUsername: (_: string) => void;
   setError?: (_: string) => void;
}> = ({ usernameIsValid, setUsername }) => {
   return (
      <FloatingLabel
         controlId="floatingUsername"
         label="Username"
         className="mb-3"
      >
         <Form.Control
            type="text"
            name="username"
            placeholder={usernameIsValid ? "Username" : "Minimum 8 characters"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setUsername(evt.target.value);
            }}
            isInvalid={!usernameIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid username.
         </Form.Control.Feedback>
      </FloatingLabel>
   );
};

export const Code: React.FunctionComponent<{
   codeIsValid: boolean;
   setCode: (_: string) => void;
}> = ({ codeIsValid, setCode }) => {
   return (
      <div className="mb-2 position-relative">
         <LockImg />
         <StyledFormControl
            type="text"
            placeholder={codeIsValid ? "Verification Code" : "Exactly 6 digits"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setCode(evt.target.value);
            }}
            isInvalid={!codeIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid code. The code has exactly 6 digits.
         </Form.Control.Feedback>
      </div>
   );
};

const formatName = value => {
   return value.replace(/[^a-z ]/gi, "");
};

export const Name: React.FunctionComponent<{
   name: string;
   nameIsValid: boolean;
   setName: (_: string) => void;
   placeholderValue: string;
}> = ({ name, nameIsValid, setName, placeholderValue }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <PersonImg />
         <StyledFormControl
            type="text"
            placeholder={
               nameIsValid ? `${placeholderValue}` : "Minimum 2 letters"
            }
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setName(evt.target.value);
            }}
            isInvalid={!nameIsValid}
            value={name}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid name.
         </Form.Control.Feedback>
      </div>
   );
};

function formatPhoneNumber(value) {
   if (!value) return value;
   const phoneNumber = value.replace(/[^\d]/g, "");
   const phoneNumberLength = phoneNumber.length;
   if (phoneNumberLength < 4) return phoneNumber;
   if (phoneNumberLength < 7) {
      return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
   }
   return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
      3,
      6,
   )}-${phoneNumber.slice(6, 10)}`;
}

export const PhoneNumber: React.FunctionComponent<{
   phoneNumberIsValid: boolean;
   setPhoneNumber: (_: string) => void;
   phoneNumber: string;
   placeholderValue: string;
}> = ({
   phoneNumberIsValid,
   setPhoneNumber,
   phoneNumber,
   placeholderValue,
}) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <PhoneImg />
         <StyledFormControl
            type="text"
            placeholder={
               phoneNumberIsValid
                  ? `${placeholderValue}`
                  : "10 digit phone number"
            }
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setPhoneNumber(formatPhoneNumber(evt.target.value));
            }}
            isInvalid={!phoneNumberIsValid}
            value={phoneNumber}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid phone number.
         </Form.Control.Feedback>
      </div>
   );
};

const formatDonorId = (value, donorId, setDonorId) => {
   if (!value) {
      setDonorId(value);
      return;
   }
   const numberOnlyString = value.match(/\d/g).join("");
   if (numberOnlyString.length > 8 || value.length > 10) return;
   setDonorId(value.replace(/[^\d-]/gi, ""));
};
export const DonorId: React.FunctionComponent<{
   donorId: string;
   donorIdIsValid: boolean;
   setDonorId: (_: string) => void;
}> = ({ donorId, donorIdIsValid, setDonorId }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <PersonVcardImg />
         <StyledFormControl
            type="text"
            placeholder={donorIdIsValid ? "Donor ID" : "Exactly 9 Digits"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               formatDonorId(evt.target.value, donorId, setDonorId);
            }}
            isInvalid={!donorIdIsValid}
            value={donorId}
         />
         <Form.Control.Feedback type="invalid">
            Donor Id contains only numbers and contain exactly 9 digits.
         </Form.Control.Feedback>
      </div>
   );
};

const maskingFormatForDateOfBirth: (string | RegExp)[] = [
   /\d/,
   /\d/,
   "/",
   /\d/,
   /\d/,
   "/",
   /\d/,
   /\d/,
   /\d/,
   /\d/,
];

const ReactDatePickerInput = React.forwardRef<MaskedInput>(
   (props: {}, ref: ForwardedRef<MaskedInput>) => {
      return (
         <MaskedInput
            mask={maskingFormatForDateOfBirth}
            ref={ref}
            {...props}
            className="form-control input-css"
            placeholderChar={"\u2000"}
         />
      );
   },
);

export const DateOfBirth: React.FunctionComponent<{
   dob: Date | null;
   setDob: (_: Date) => void;
}> = ({ dob, setDob }) => {
   return (
      <DateOfBirthDiv className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <InputGroup>
            <CalendarDateImg />
            <DatePickerReact
               selected={dob}
               onChange={date => setDob(date)}
               placeholderText="Date of Birth (MM/DD/YYYY)"
               showMonthDropdown
               showYearDropdown
               dropdownMode="select"
               maxDate={new Date()}
               customInput={<ReactDatePickerInput />}
            />
         </InputGroup>
      </DateOfBirthDiv>
   );
};

export const StreetAddress: React.FunctionComponent<{
   streetAddressIsValid: boolean;
   setStreetAddress: (_: string) => void;
}> = ({ streetAddressIsValid, setStreetAddress }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <StreetAddressImg />
         <StyledFormControl
            type="text"
            placeholder={
               streetAddressIsValid ? "Street Address" : "Minimum 2 letters"
            }
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setStreetAddress(evt.target.value);
            }}
            isInvalid={!streetAddressIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid street address.
         </Form.Control.Feedback>
      </div>
   );
};

export const City: React.FunctionComponent<{
   cityIsValid: boolean;
   setCity: (_: string) => void;
}> = ({ cityIsValid, setCity }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <CityBuildingsImg />
         <StyledFormControl
            type="text"
            placeholder={cityIsValid ? "City" : "Minimum 2 letters"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setCity(evt.target.value);
            }}
            isInvalid={!cityIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid City.
         </Form.Control.Feedback>
      </div>
   );
};

export const ZipCode: React.FunctionComponent<{
   zipcodeIsValid: boolean;
   setZipCode: (_: string) => void;
}> = ({ zipcodeIsValid, setZipCode }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <FormControl
            type="text"
            placeholder={zipcodeIsValid ? "Zip Code" : "Minimum 5 letters"}
            onChange={(evt: React.ChangeEvent<HTMLTextAreaElement>) => {
               setZipCode(evt.target.value);
            }}
            isInvalid={!zipcodeIsValid}
         />
         <Form.Control.Feedback type="invalid">
            Please enter a valid Zip Code.
         </Form.Control.Feedback>
      </div>
   );
};

export const Gender: React.FunctionComponent<{
   gender: string;
   setGender: (_: string) => void;
}> = ({ gender, setGender }) => {
   return (
      <div className="position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <GenderRadioSection>
            <Form.Check
               inline
               label="Male"
               name="group1"
               type="radio"
               value="M"
               id="male-radio"
               checked={gender === "M"}
               onChange={e => setGender(e.target.value)}
            />
            <Form.Check
               inline
               label="Female"
               name="group1"
               type="radio"
               id="female-radio"
               value="F"
               checked={gender === "F"}
               onChange={e => setGender(e.target.value)}
            />
         </GenderRadioSection>
      </div>
   );
};

export const StatesDropdown: React.FunctionComponent<{
   stateVal: string;
   setStateVal: (_: string) => void;
}> = ({ stateVal, setStateVal }) => {
   return (
      <div className="mb-3 position-relative">
         <RequiredFieldSpanStyled>*</RequiredFieldSpanStyled>
         <FormSelectOptions
            value={stateVal}
            onChange={e => setStateVal(e.target.value)}
         >
            {US_STATES.map(item => {
               return (
                  <option key={item.abbreviation} value={item.abbreviation}>
                     {item.name}
                  </option>
               );
            })}
         </FormSelectOptions>
      </div>
   );
};
