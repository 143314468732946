import Footer from "app/components/Footer";
import React, { useContext } from "react";
import { MobileLayoutSteps } from "app/consts";
import BasicLayoutOpportunityList from "../../BasicLayout/OpportunityMapSection/BasicLayoutOpportunityList";
import { Div, MapButton, BorderedShadow, Container } from "./styled";
import { ReactComponent as MapIcon } from "assets/svgs/map.svg";
import { OpportunitySearchBar } from "../../../components/OpportunitySearchBar/OpportunitySearchBar";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "app/components/OpportunitySearchComponent/context/OpportunitySearchContext";

interface MobileListViewWithFiltersProps {
   setLayout: (arg: MobileLayoutSteps) => void;
}

const MobileListViewWithFilters: React.FC<MobileListViewWithFiltersProps> = ({
   setLayout,
}) => {

   const { scrolledItems } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   // Conditionally render the MapButton. (AEP-60)
   const mapButton = scrolledItems.length > 0 && (
      <Div className="d-flex justify-content-center">
         <MapButton onClick={() => setLayout(MobileLayoutSteps.MOBILE_MAP_VIEW)}>
            <MapIcon />
            View Map
         </MapButton>
      </Div>
   );

   return (
      <Container>
         <BorderedShadow>
            <OpportunitySearchBar />
         </BorderedShadow>
         <BasicLayoutOpportunityList />
         <Footer />

         <Div className="d-flex justify-content-center">

            {/* Render the MapButton if opportunity list is not empty. (AEP-60) */}
            {mapButton}
         </Div>
      </Container>
   );
};

export default MobileListViewWithFilters;
