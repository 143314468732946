import React from "react";
import { IAppointment } from "types/IAppointment";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import { AppointmentSlot } from "../../../../../../components/AppointmentSlot/AppointmentSlot";
import { getIconForLocationType } from "../../../../../../components/LocationTypeImageIcon/getIconForLocationType";

interface PastAppointmentSlotProps {
   appointment: IAppointment;
}

const PastAppointmentDesktopSlot: React.FC<PastAppointmentSlotProps> = ({
   appointment,
}) => {
   const Month: string = format(
      new Date(appointment.appointmentDateTime),
      "MMM",
   );
   const Day: string = format(new Date(appointment.appointmentDateTime), "dd");

   return (
      <AppointmentSlot
         month={Month}
         day={Day}
         iconSrc={getIconForLocationType(
            appointment?.drive?.location?.locationType,
         )}
         name={appointment?.drive?.location?.name}
         subTitle={getStrictFormattedAddress(appointment?.drive?.location)}
         rightTopLabel={"Appt Type"}
         rightTopValue={appointment?.appointmentTypeDescription}
         rightBottomLabel={"Status"}
         rightBottomValue={appointment.outcome}
      />
   );
};

export default PastAppointmentDesktopSlot;
