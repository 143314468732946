import { AbstractBexWISEService } from "../bexWISEResolutionService/AbstractBexWISEService";
import {
   IBloodDriveDataService,
   IDriveAppointmentAvailability,
   IGetDrivesRequest,
} from "./IBloodDriveDataService";
import { IBloodDrive } from "../../../types/IBloodDrive";

export abstract class AbstractBloodDriveDataService
   extends AbstractBexWISEService
   implements IBloodDriveDataService
{
   // driveDate must be in the form YYYY-MM-DD
   public abstract getDriveAppointments(
      driveId: string,
      driveDate: string,
   ): Promise<IDriveAppointmentAvailability[]>;

   // driveDate must be in the form YYYY-MM-DD
   public abstract getDrive(
      driveId: string,
      driveDate: string,
   ): Promise<IBloodDrive>;

   public abstract getDrives(
      getDrivesRequest: IGetDrivesRequest,
   ): Promise<IBloodDrive[]>;
}
