import React, { useContext, useEffect, useState, useRef } from "react";
import {
   LocationOption,
   LocationOrAccountOptions,
   SortBy,
} from "app/consts";
import { AimIcon, FormInput, SearchIcon } from "../../Searchbar/styled";
import { CommonContext } from "app/contexts/common/commonContext";
import {
   IOpportunitySearchContext,
   OpportunitySearchContext,
} from "../../../context/OpportunitySearchContext";
import { debounce } from 'lodash';
import { DateKind } from "app/components/Form/Inputs/Generic/FormDatePicker/DatePicker/DateKind";

export interface IOpportunitySearchFilter {
   searchType: LocationOrAccountOptions;
   accountName: string;
   searchOrigin: string;
   radiusMiles: string;
   startDate: Date;
   endDate: Date;
   locationType: LocationOption;
   selectedAppointmentTypes: string[] | null;
   sortBy: SortBy;
   dateKind: DateKind;
}

interface SearchbarProps {
   name: string;
   styles?: React.CSSProperties;
}

export const Searchbar: React.FC<SearchbarProps> = ({ name, styles = {} }) => {
   const { filters, updateFilter } = useContext<IOpportunitySearchContext>(
      OpportunitySearchContext,
   );

   const [searchBarValue, setSearchBarValue] = useState<string>(
      filters?.searchType === LocationOrAccountOptions.LOCATION
         ? filters.searchOrigin
         : filters.accountName,
   );
   const { currentOriginZipCodeFromBrowser, defaultOriginZipCode } =
      useContext(CommonContext);

   const inputRef = useRef<HTMLInputElement>(null); // Create a ref for the input field (AEP-61)

   useEffect(() => {
      setSearchBarValue(
         filters?.searchType === LocationOrAccountOptions.LOCATION
            ? filters.searchOrigin
            : filters.accountName,
      );
      // After filters have been updated, focus the input field (AEP-61)
      if (inputRef.current) {
         inputRef.current.focus();
      }
   }, [filters.searchType]); // Only run when search type was changed (AEP-61)

   const isShowAimIcon: boolean =
      filters?.searchType === LocationOrAccountOptions.LOCATION;
   const placeholder: string = isShowAimIcon
      ? "Search zip code, city/state or county"
      : "Search account name ";

   const handleOnBlur = (): void => { };

   const setCurrentZipCode = () => {
      updateFilter({
         searchOrigin: `${currentOriginZipCodeFromBrowser || defaultOriginZipCode
            }`,
      });
   };

   const onChange = (newValue: string) => {
      setSearchBarValue(newValue);
      // Delay the update process until the user is done typing (AEP-61)
      if (filters?.searchType === LocationOrAccountOptions.LOCATION) {
         updateFilterDebounced({
            ...filters,
            searchOrigin: newValue,
         });
      } else {
         updateFilterDebounced({
            ...filters,
            accountName: newValue,
         });
      }
   };

   const updateFilterDebounced = useRef(
      debounce((newFilters: Partial<IOpportunitySearchFilter>) => {
         updateFilter(newFilters);
      }, 300)
   ).current;

   return (
      <div className="position-relative">
         <SearchIcon fill="grey" />
         <FormInput
            ref={inputRef} // Set the input field reference
            type="text"
            name={name}
            placeholder={placeholder || ""}
            value={searchBarValue}
            styles={{ ...styles }}
            onChange={change => onChange(change.target.value)}
            onBlur={handleOnBlur}
         />

         {isShowAimIcon && <AimIcon onClick={() => setCurrentZipCode()} />}
      </div>
   );
};

export default Searchbar;
