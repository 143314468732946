import FullScreenLoader from "app/components/FullScreenLoader";
import React, { useContext, useState } from "react";
import { getZipCodeBasedOnLatLng } from "utils/getZipCodeBasedOnLatLng";
import { EnvironmentContext } from "../environment/environmentContext";
export interface CommonContextType {
   isLoading: boolean;
   startLoading: () => void;
   stopLoading: () => void;
   defaultOriginZipCode: number | undefined;
   currentOriginZipCodeFromBrowser: number | undefined;
}

interface CommonContextProps {
   children: React.ReactNode;
}

const initialState: CommonContextType = {
   isLoading: false,
   startLoading: () => {},
   stopLoading: () => {},
   defaultOriginZipCode: 0,
   currentOriginZipCodeFromBrowser: 0,
};

export const CommonContext =
   React.createContext<CommonContextType>(initialState);

export const CommonContextProvider: React.FC<CommonContextProps> = ({
   children,
}) => {
   const { environmentConfig } = useContext(EnvironmentContext);
   const [isLoading, setShowLoader] = useState<boolean>(false);
   const [defaultOriginZipCode, setDefaultOriginZipCode] = useState<
      number | undefined
   >(environmentConfig?.defaultZip);
   const [currentOriginZipCodeFromBrowser, setCurrentOriginZipCodeFromBrowser] =
      useState<number | undefined>();
   const startLoading = (): void => {
      setShowLoader(true);
   };

   const stopLoading = (): void => {
      setShowLoader(false);
   };

   const getZipCodeCall = async pos => {
      var crd = pos.coords;
      const zipCode = await getZipCodeBasedOnLatLng(
         `${crd.latitude},${crd.longitude}`,
      );
      if (zipCode) {
         setCurrentOriginZipCodeFromBrowser(Number(zipCode));
      }
   };

   React.useEffect(() => {
      if (navigator.geolocation) {
         navigator.permissions
            .query({ name: "geolocation" })
            .then(function (result) {
               if (result.state === "granted") {
                  navigator.geolocation.getCurrentPosition(getZipCodeCall);
               }
            })
            .catch(err => {});
      }
   }, [defaultOriginZipCode]);

   return (
      <CommonContext.Provider
         value={{
            isLoading,
            startLoading,
            stopLoading,
            defaultOriginZipCode,
            currentOriginZipCodeFromBrowser,
         }}
      >
         {isLoading && <FullScreenLoader />}
         {children}
      </CommonContext.Provider>
   );
};
