import React from "react";
import { format } from "date-fns";
import { getStrictFormattedAddress } from "utils/getStrictFormattedAddress";
import donationTitleIcon from "assets/images/donationTitleIcon.png";
import { IDonorDonationDTO } from "services/bexWISE/donorDataService/IDonorDataService";
import { AppointmentSlot } from "../../../../../components/AppointmentSlot/AppointmentSlot";
interface MyDonationSlotProps {
   donation: IDonorDonationDTO;
}

const MyDonationDesktopSlot: React.FC<MyDonationSlotProps> = ({ donation }) => {
   const Month: string = format(new Date(donation?.date), "MMM");
   const Day: string = format(new Date(donation?.date), "dd");

   const mapOutcomeToBadge = (outcome: string): "SUCCESS" | "INFO" | "FAIL" => {
      switch (outcome) {
         case "Successful":
            return "SUCCESS";
         case "Failed":
            return "FAIL";
         case "Deferred":
            return "INFO";
         default:
            return "INFO";
      }
   };

   return (
      <AppointmentSlot
         month={Month}
         day={Day}
         iconSrc={donationTitleIcon}
         name={donation?.donationProcedureDescription}
         subTitle={getStrictFormattedAddress(donation?.location)}
         rightTopLabel={"Type"}
         rightTopValue={"Allogeneic"}
         rightBottomLabel={"Credit To"}
         rightBottomValue={donation?.creditToLocation?.name}
         status={{
            type: mapOutcomeToBadge(donation.outcome),
            text: donation.outcome,
         }}
      />
   );
};

export default MyDonationDesktopSlot;
